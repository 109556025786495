/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import AppBar from '@mui/material/AppBar';
import logo from '../../assets/image_png.png';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Box, Tooltip, Typography } from '@mui/material';
import { InvoiceContext } from "../../context/invoiceContext";
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes.json";

function ResponsiveAppBar({
    screenTitle,
    isLoginPage
}) {
    const { signedInUser } = useContext(InvoiceContext);

    const navigate = useNavigate();
    const onClickHandler = (path) => {
        navigate(path);
    };

    return (
        <AppBar position="fixed" sx={{
            backgroundColor: (theme) => theme.palette.primary.main,
            height: "30px"
        }}>
            {
                !isLoginPage &&
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    height: '100%',
                    padding: '0 20px'
                }}>
                    <Box sx={{ flex: 1, alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Tooltip title="Home">
                            <img onClick={() => signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? onClickHandler(routes?.HOME) : onClickHandler(routes?.INVOICE)} src={logo} alt="Logo" style={{ maxHeight: '20px', cursor: 'pointer' }} />
                        </Tooltip>
                    </Box> {/* Empty Box to push screenTitle to the center */}
                    <Typography sx={{ textAlign: 'center' }}>
                        {screenTitle}
                    </Typography>
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        flex: 1
                    }}>
                        <Tooltip title={signedInUser?.name}>
                            <AccountCircleIcon
                                sx={{
                                    cursor: 'pointer',
                                    fontSize: '15px'
                                }}
                            />
                        </Tooltip>
                        <Typography sx={{ marginLeft: '5px', fontSize: '12px' }}>
                            Hi, {signedInUser?.name}
                        </Typography>
                    </Box>
                </Box>
            }
        </AppBar>

    );
}
export default ResponsiveAppBar;
