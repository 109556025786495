import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        background: {
            main: '#F57C00',
        },
        primary: {
            whitish: '#90caf9',
            lightplus: '#64b5f6',
            light: '#42a5f5',
            main: '#2196f3',
            dark: '#1e88e5',
            darkplus: '#1976d2',
        },
        secondary: {
            main: '#F57C00',
        },
        error: {
            main: '#f44336',
        },
    },
    typography: {
        fontFamily: 'DM Sans',
        fontWeightLight: 200,
        fontWeightMedium: 300,
        fontWeightRegular: 400,
        textOverflow: 'ellipsis',
        header20: { fontSize: '1.25rem', fontWeight: 200, fontFamily: 'DM Sans' },
        h1: {
            fontSize: '2rem',
            fontWeight: 200,
            fontFamily: 'DM Sans',
        },
        h2: {
            fontSize: '0.875rem',
            fontWeight: 300,
            fontFamily: 'DM Sans',
        },
        h3: {
            fontSize: '0.75rem',
            fontWeight: 300,
            fontFamily: 'DM Sans',
        },
        h4: {
            fontSize: '1rem',
            fontWeight: 300,
            fontFamily: 'DM Sans',
        },
        h5: {
            fontSize: '1.25rem',
            fontWeight: 300,
            fontFamily: 'DM Sans',
        },
    },
    components: {
        MuiTooltip: {
            defaultProps: {
                arrow: true
            },
            styleOverrides: {
                tooltip: {
                    backgroundColor: '#C0C0C0',
                    color: 'black'
                },
                arrow: {
                    color: '#C0C0C0'
                }
            }
        },
        MuiIconButton: {
            defaultProps: {
                disableRipple: true,
            },
            styleOverrides: {
                root: {
                    padding: '0px 3px 3.5px 3px',
                }
            }
        },
    }
});

export default theme;