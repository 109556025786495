/* eslint-disable react/prop-types */
import React from 'react'
import { Grid, Box } from '@mui/material'
import StallionLogo from '../../assets/stallion_infrastructure_services_logo.jpg';

function LoaderComponent({
    ht
}) {
    return (
        <Grid
            container
            style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: ht || '100%',
                width: "100%"
            }}>
            <Box
                component="img"
                sx={{
                    width: '10%',
                    opacity: 0.5,
                    border: '1px solid white',
                    borderRadius: '100px'
                }}
                alt="Stallion Infrastructure"
                src={StallionLogo}
            />
        </Grid>
    )
}

export default LoaderComponent
