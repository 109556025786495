import React from 'react';
import { Grid } from '@mui/material';
import "./style.css";

const LoadingRowSkeleton = () => {
    return (
        <Grid className="loading-row" sx={{width: '100%'}}>
            <Grid className="loading-cell" sx={{ width: '100%' }}>
                    &nbsp;
            </Grid>
        </Grid>
    );
};

export default LoadingRowSkeleton;