export const Capitalize = string => {
    const firstLetter = string?.slice(0, 1);
    // eslint-disable-next-line no-unsafe-optional-chaining
    return firstLetter.toUpperCase() + string?.slice(1).toLowerCase();
};

export const stringReducer = (string, count) => {
    const maxLength = count || 14;

    let truncatedText = string?.slice(0, maxLength);

    if (truncatedText?.length < string?.length) {
        truncatedText += '...';
    }

    return truncatedText;
};

export const validateInput = (tagName) => {
    // Regex explanation:
    // ^[^\s@]        : Ensures the string does not start with a space or an '@'
    // [a-zA-Z0-9-_.\s]* : Matches any combination of allowed characters and spaces
    // $              : End of the string
    const regex = /^[^\s@][a-zA-Z0-9-_.\s]*$/;
    return regex.test(tagName);
};


export const changeDateFormat = (date) => {
    const [month, day, year] = date.split('/').map(part => parseInt(part));
    const formattedMonth = month < 10 ? `0${month}` : `${month}`;
    const formattedDay = day < 10 ? `0${day}` : `${day}`;
    return `${year}-${formattedMonth}-${formattedDay}`;
}

export const DateFormatPayload = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const ConvertUTCToLocalTime = (utcDateStr) => {
    const localDate = new Date(utcDateStr);
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
    return localDate.toLocaleString(
        userLocale, {
        month: '2-digit', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true // Ensures AM/PM format based on user's locale
    });
}

export const ConvertUTCToLocalDate = (utcDateStr) => {
    const localDate = new Date(utcDateStr);
    const userLocale = Intl.DateTimeFormat().resolvedOptions().locale;
    return localDate.toLocaleString(
        userLocale, {
        month: '2-digit', day: '2-digit', year: 'numeric'
    });
}