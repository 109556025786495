/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react'
import { Box, Card, Select, MenuItem, Grid, Button, Tooltip, Typography } from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput';
import DatePicker from "react-datepicker";
import { stringReducer } from '../../utils';
import CustomizedSnackbars from '../snackbar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SearchBar from '../searchbar';
import { FixedSizeList as List } from 'react-window';

// css imports
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";

import { InvoiceContext } from '../../context/invoiceContext';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
};

function FilterCards({
    FilterTitle,
    filterappliedcallback,
    clearfilter,
    IsDateFilter,
    FilterData,
    selectedfiltercallback,
    passedStatus,
    filterFrom,
    filterTo,
    setDateCallback,
    customerLoading,
    isInvoiceScreen,
    enableFilters,
}) {
    const [filterName, setFilterName] = useState([]);
    const [filterItems, setFilterItems] = useState([]);
    const [date, setDate] = useState(new Date());
    const date90DaysAgo = new Date();
    date90DaysAgo.setDate(date90DaysAgo.getDate() - process.env.REACT_APP_DAYS_FROM_DATE);
    const [fromDate, setFromDate] = useState(date90DaysAgo);
    const { selectedStatus, InitializeStatus, appliedFilter } = useContext(InvoiceContext);
    const [action, setAction] = useState(false);
    const [Message, SetMessage] = useState("")
    const [snacktype, setSnacktype] = useState("");
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState([]);
    const [isDateFilterApplied, checkDateFilterApplied] = useState(false);
    const listRef = useRef(null);

    useEffect(() => {
        populateFilterItems();
        if (passedStatus) {
            setFilterName(typeof selectedStatus?.invoiceStatus === 'string' ? selectedStatus?.invoiceStatus?.split(',') : selectedStatus);
        }
        if (isInvoiceScreen && appliedFilter) {
            if (appliedFilter.fromDate !== null && FilterTitle === "From Date") {
                const curFromDate = formatDateToString(fromDate);
                if (curFromDate !== appliedFilter.fromDate) {
                    const event = convertToISO(appliedFilter?.fromDate);
                    setFromDate(event);
                    setDateCallback(event);
                    checkDateFilterApplied(true);
                }
            }
            if (appliedFilter.toDate !== null && FilterTitle === "To Date") {
                const curToDate = formatDateToString(date);
                if (curToDate !== appliedFilter.toDate) {
                    const event = convertToISO(appliedFilter?.toDate);
                    setDate(event);
                    setDateCallback(event);
                    checkDateFilterApplied(true);
                }
            }
            if (Array.isArray(appliedFilter.customerId) && FilterTitle === "Customer Name") {
                const names = appliedFilter.customerId.map((id) => getFilterName(id, "Customer Name"));
                const validNames = names.filter((name) => name);
                if (validNames.length > 0) {
                    const nameArray = validNames.map((name) => name?.Name);
                    setFilterName(nameArray);
                    setSelectedItem(validNames);
                }
            }
            if (appliedFilter.division !== null && FilterTitle === "Division") {
                const name = getFilterName(appliedFilter?.division, "Division");
                if (name) {
                    setFilterName(typeof name?.Name === 'string' ? name?.Name?.split(',') : name);
                    setSelectedItem(name);
                }
            }
            if (appliedFilter.statusId !== null && FilterTitle === "Status") {
                const name = getFilterName(appliedFilter?.statusId, "Status");
                if (name) {
                    if (passedStatus) {
                        setFilterName(typeof selectedStatus?.invoiceStatus === 'string' ? selectedStatus?.invoiceStatus?.split(',') : selectedStatus);
                    }
                    else {
                        setFilterName(typeof name?.Name === 'string' ? name?.Name?.split(',') : name);
                        setSelectedItem(name);
                    }
                }
            }
            if (appliedFilter.disputeCodeId !== null && FilterTitle === "Dispute Code") {
                const name = getFilterName(appliedFilter?.disputeCodeId, "Dispute Code");
                if (name) {
                    setFilterName(typeof name?.Name === 'string' ? name?.Name?.split(',') : name);
                    setSelectedItem(name);
                }
            }
        }
        if (clearfilter) {
            setFilterName([]);
            setSelectedItem([]);
            setDate(new Date());
            checkDateFilterApplied(false);
            const date90DaysAgo = new Date();
            date90DaysAgo.setDate(date90DaysAgo.getDate() - process.env.REACT_APP_DAYS_FROM_DATE);
            setFromDate(date90DaysAgo);
        }
    }, [FilterData, clearfilter]);

    const getFilterName = (id, title) => {
        if (!FilterData) return null; // Handle case when FilterData is not defined
        let tempFilter;
        switch (title) {
            case "Customer Name":
                tempFilter = FilterData.filter(data => data.customerId === id)
                    .map((data) => {
                        const filterObj = {
                            Id: data.customerId,
                            Name: data.customerName
                        }
                        return filterObj;
                    });
                break;
            case "Division":
                tempFilter = FilterData.filter(data => data === id).map((data, index) => {
                    const filterObj = {
                        Id: index,
                        Name: data
                    }
                    return filterObj;
                })
                break;
            case "Status":
                tempFilter = FilterData.filter(data => data.statusId === id)
                    .map((data) => {
                        const filterObj = {
                            Id: data.statusId,
                            Name: data.statusName
                        }
                        return filterObj;
                    })
                break;
            case "Dispute Code":
                tempFilter = FilterData.filter(data => data.disputeCodeId === id)
                    .map((data) => {
                        const filterObj = {
                            Id: data.disputeCodeId,
                            Name: data.disputeCode
                        }
                        return filterObj;
                    })
                break;
        }
        return tempFilter.length > 0 ? tempFilter[0] : null;
    }

    const populateFilterItems = () => {
        if (FilterData) {
            if (FilterTitle === "Customer Name") {
                const tempFilter = FilterData.map((data) => {
                    const filterObj = {
                        Id: data.customerId,
                        Name: data.customerName
                    }
                    return filterObj;
                })
                if (isInvoiceScreen) {
                    setFilterItems(tempFilter);
                }
                else {
                    setFilterItems([{ Id: null, Name: "All" }, ...tempFilter]);
                }
            }
            else if (FilterTitle === "Division") {
                const tempFilter = FilterData.map((data, index) => {
                    const filterObj = {
                        Id: index,
                        Name: data
                    }
                    return filterObj;
                })
                setFilterItems([{ Id: null, Name: "All" }, ...tempFilter]);
            }
            else if (FilterTitle === "Status") {
                const tempFilter = FilterData.map((data) => {
                    const filterObj = {
                        Id: data.statusId,
                        Name: data.statusName
                    }
                    return filterObj;
                })
                setFilterItems([{ Id: null, Name: "All" }, ...tempFilter]);
            }
            else {
                const tempFilter = FilterData.map((data) => {
                    const filterObj = {
                        Id: data.disputeCodeId,
                        Name: data.disputeCode
                    }
                    return filterObj;
                })
                setFilterItems([{ Id: null, Name: "All" }, ...tempFilter]);
            }
        }
    }

    const convertToISO = (dateString) => {
        const date = new Date(dateString);
        date.setUTCHours(0, 0, 0, 0);
        return date;
    }

    const formatDateToString = (date) => {
        return date.toISOString().split('T')[0];
    }

    const handleChange = (event) => {
        if (FilterTitle === "From Date") {
            if (event) {
                if (event <= new Date() && event <= filterTo) {
                    const x = formatDateToString(event);
                    const y = formatDateToString(fromDate)
                    if (x !== y) {
                        setFromDate(event);
                        setDateCallback(event);
                        checkDateFilterApplied(true);
                        const newCalenderTitle = event.toLocaleDateString();
                        if (selectedfiltercallback) {
                            selectedfiltercallback(newCalenderTitle, FilterTitle);
                        }
                        if (filterappliedcallback) {
                            filterappliedcallback(true);
                        }
                    }
                } else {
                    setAction(true);
                    setSnacktype("info");
                    if (event > filterTo) {
                        SetMessage('From Date cannot be after To Date');
                    }
                    else {
                        SetMessage('From Date cannot be a future date');
                    }
                }
            }
        } else if (FilterTitle === "To Date") {
            if (event) {
                if (event >= filterFrom && event <= new Date()) {
                    const x = formatDateToString(event);
                    const y = formatDateToString(date)
                    if (x !== y) {
                        setDate(event);
                        setDateCallback(event);
                        checkDateFilterApplied(true);
                        const newCalenderTitle = event.toLocaleDateString();
                        if (selectedfiltercallback) {
                            selectedfiltercallback(newCalenderTitle, FilterTitle);
                        }
                        if (filterappliedcallback) {
                            filterappliedcallback(true);
                        }
                    }
                } else {
                    setAction(true);
                    setSnacktype("info");
                    SetMessage('To Date cannot be earlier than From Date or a future date');
                }
            }
        }
        else {
            if (isInvoiceScreen && FilterTitle === "Customer Name") {
                const value = event.target;
                if (value) {
                    if (value.Name) {

                        setFilterName((prevFilters) => {
                            const currentFilters = [...prevFilters];
                            const existsInSelectedItem = selectedItem.some((item) => item.Id === value.Id);

                            if (existsInSelectedItem) {
                                const updatedFilters = selectedItem.filter((item) => item.Id !== value.Id).map((item) => item.Name);
                                return updatedFilters;
                            } else {
                                return [value.Name, ...currentFilters];
                            }
                        });

                        setSelectedItem((prevSelected) => {
                            const currentSelected = [...prevSelected];
                            const exists = currentSelected.some(item => item.Id === value.Id);
                            if (exists) {
                                return currentSelected.filter((item) => item.Id !== value.Id);
                            } else {
                                return [value, ...currentSelected];
                            }
                        });
                        if (filterappliedcallback) {
                            filterappliedcallback(true);
                        }
                        if (selectedfiltercallback) {
                            selectedfiltercallback(value, FilterTitle);
                        }
                    } else {
                        setOpen(true);
                    }
                }
            } else {
                const value = event.target;
                if (value) {
                    if (value.Name) {
                        if (FilterTitle === "Status") {
                            InitializeStatus();
                        }
                        setFilterName(typeof value.Name === 'string' ? value.Name.split(',') : value);
                        setSelectedItem(value);
                        setOpen(false);
                        if (filterappliedcallback) {
                            filterappliedcallback(true);
                        }
                        if (selectedfiltercallback) {
                            selectedfiltercallback(value, FilterTitle);
                        }
                    } else {
                        setOpen(true);
                    }
                }
            }
        }
    };

    const handleSnapClose = (value) => {
        setAction(value);
    };

    const CalenderCustomInput = forwardRef(({ value, onClick }, ref) => (
        <Tooltip
            title={FilterTitle}
            placement="top"
        >
            <Button
                disabled={enableFilters}
                onClick={onClick}
                ref={ref}
                x
                sx={{
                    height: 25,
                    width: '100%',
                    color: 'white',
                    fontSize: 12,
                    backgroundColor: isDateFilterApplied ? "#1976d2" : 'inherit',
                    ":hover": {
                        backgroundColor: isDateFilterApplied ? "#1976d2" : 'inherit',
                    }
                }}
                endIcon={<CalendarMonthIcon
                    style={{
                        fontSize: 14,
                    }}
                />}
            >
                {value}
            </Button>
        </Tooltip>
    ));
    CalenderCustomInput.displayName = "CalenderCustomInput";

    const handleFilterSearch = (searchTerm) => {
        if (searchTerm !== "") {
            const lowercasedTerm = searchTerm.toLowerCase();
            const filteredItems = FilterData.filter((item) => {
                if (FilterTitle === "Customer Name") {
                    return item.customerName.toLowerCase().includes(lowercasedTerm);
                } else if (FilterTitle === "Division") {
                    return item.toLowerCase().includes(lowercasedTerm);
                } else if (FilterTitle === "Status") {
                    return item.statusName.toLowerCase().includes(lowercasedTerm);
                } else {
                    return item.disputeCode.toLowerCase().includes(lowercasedTerm);
                }
            }).map((data) => {
                if (FilterTitle === "Customer Name") {
                    return {
                        Id: data.customerId,
                        Name: data.customerName ? data.customerName : data.customerId
                    };
                } else if (FilterTitle === "Division") {
                    return {
                        Id: FilterData.indexOf(data),
                        Name: data
                    };
                } else if (FilterTitle === "Status") {
                    return {
                        Id: data.statusId,
                        Name: data.statusName
                    };
                } else {
                    return {
                        Id: data.disputeCodeId,
                        Name: data.disputeCode
                    };
                }
            });
            setFilterItems(filteredItems);
        }
        else {
            populateFilterItems();
        }
    };

    const renderCustomerRow = ({ index, style }) => {
        const filter = filterItems[index];
        const filterName = filter.Name;
        const showTooltip = filterName.length > 20;

        const menuItem = (
            <MenuItem
                key={filter.Id}
                value={{ Id: filter.Id, Name: filter.Name }}
                style={style}
                sx={{
                    fontSize: 10,
                    backgroundColor: (FilterTitle === 'Status' && selectedStatus) ?
                        (selectedStatus.currentStatusId === filter.Id) ? '#2196f3' : 'inherit'
                        : (selectedItem.some(item => item.Id === filter.Id)) ? '#2196f3' : 'inherit',
                    color: (FilterTitle === 'Status' && selectedStatus) ?
                        (selectedStatus.currentStatusId === filter.Id) ? 'white' : 'black'
                        : (selectedItem.some(item => item.Id === filter.Id)) ? 'white' : 'black',
                    border: '1px solid white',
                    ":hover": {
                        border: '1px solid white',
                        backgroundColor:
                            (FilterTitle === 'Status' && selectedStatus) ?
                                (selectedStatus.currentStatusId === filter.Id) ? '#90caf9' : '#2196f3'
                                : (selectedItem.some(item => item.Id === filter.Id)) ? '#90caf9' : '#2196f3',
                        color: 'white',
                    }
                }}
                onClick={() => handleChange({ target: { Id: filter.Id, Name: filter.Name } })}
            >
                {stringReducer(filter.Name, 18)}
            </MenuItem>
        );
        return showTooltip ? (
            <Tooltip title={filterName} placement='right'>
                {menuItem}
            </Tooltip>
        ) : (
            menuItem
        );
    };

    const renderRow = ({ index, style }) => {
        const filter = filterItems[index];
        const filterName = filter.Name;
        const showTooltip = filterName.length > 20;

        const menuItem = (
            <MenuItem
                key={filter.Id}
                value={{ Id: filter.Id, Name: filter.Name }}
                style={style}
                sx={{
                    fontSize: 10,
                    backgroundColor: (FilterTitle === 'Status' && selectedStatus) ?
                        (selectedStatus.currentStatusId === filter.Id) ? '#2196f3' : 'inherit'
                        : (selectedItem.Id === filter.Id) ? '#2196f3' : 'inherit',
                    color: (FilterTitle === 'Status' && selectedStatus) ?
                        (selectedStatus.currentStatusId === filter.Id) ? 'white' : 'black'
                        : (selectedItem.Id === filter.Id) ? 'white' : 'black',
                    border: '1px solid white',
                    ":hover": {
                        border: '1px solid white',
                        backgroundColor:
                            (FilterTitle === 'Status' && selectedStatus) ?
                                (selectedStatus.currentStatusId === filter.Id) ? '#90caf9' : '#2196f3'
                                : (selectedItem.Id === filter.Id) ? '#90caf9' : '#2196f3',
                        color: 'white',
                    }
                }}
                onClick={() => handleChange({ target: { Id: filter.Id, Name: filter.Name } })}
            >
                {stringReducer(filter.Name, 18)}
            </MenuItem>
        );

        return showTooltip ? (
            <Tooltip title={filterName} placement='right'>
                {menuItem}
            </Tooltip>
        ) : (
            menuItem
        );
    };

    const handleOpen = () => {
        if (selectedItem.length !== 0) {
            let index = filterItems.findIndex(item => item.Id === selectedItem.Id);
            if (isInvoiceScreen && FilterTitle === "Customer Name") {
                index = filterItems.findIndex(item => item.Id === selectedItem[0].Id);
            }
            setTimeout(() => {
                if (listRef.current) {
                    listRef.current.scrollToItem(index + 3);
                }
            }, 100);
        }
    };

    return (
        <>
            <Grid
                item
                xs={12}
                sm={(FilterTitle === "Customer Name") ? 1.4 : 1.2}
                md={(FilterTitle === "Customer Name") ? 1.4 : 1.2}
                lg={(FilterTitle === "Customer Name") ? 1.4 : 1.2}
            >
                <Card
                    sx={{
                        display: 'flex',
                        width: '100%',
                        height: 25,
                        fontSize: 12,
                        backgroundColor: (theme) => theme.palette.primary.main,
                        color: 'white',
                    }}>
                    {
                        (IsDateFilter) ? (
                            <DatePicker
                                selected={(FilterTitle === "From Date") ? fromDate : date}
                                onChange={handleChange}
                                customInput={
                                    <CalenderCustomInput />
                                }
                                popperPlacement='top-end'
                                showMonthDropdown
                                showYearDropdown
                                minDate={new Date("2016-01-01")}
                                maxDate={new Date()}
                                dropdownMode="select"
                            />
                        ) : (
                            <Select
                                multiple={isInvoiceScreen && FilterTitle === "Customer Name"}
                                displayEmpty
                                open={open}
                                onClose={() => setOpen(false)}
                                onOpen={() => {
                                    setOpen(true);
                                    handleOpen();
                                }}
                                onChange={handleChange}
                                disabled={enableFilters}
                                value={filterName}
                                input={<OutlinedInput
                                    sx={{
                                        width: '100%',
                                        color: 'white',
                                        height: '100%',
                                        fontSize: 12,
                                    }}
                                />}
                                renderValue={(selected) => {
                                    if (selected.length === 0) {
                                        return <Box>{FilterTitle}</Box>;
                                    }
                                    return stringReducer(selected.join(', '), 20);
                                }}
                                MenuProps={MenuProps}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    color: 'white',
                                    backgroundColor: filterName.length !== 0 ? "#1976d2" : "inherit",
                                    fontSize: 12,
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    },
                                    '&.Mui-focused fieldset': {
                                        border: 'none',
                                    },
                                    '.MuiSelect-icon': {
                                        color: 'white'
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '2px',
                                        height: 25,
                                        display: 'flex',
                                        alignItems: 'center',
                                        marginBottom: '2px',
                                    }}
                                >
                                    <SearchBar
                                        placeholder={`${FilterTitle}`}
                                        isAutoFocus={true}
                                        searchApplyCallBack={handleFilterSearch}
                                        isSerachIcon={false}
                                        isCrossIcon={false}
                                        textColor="#757575"
                                    />
                                </Box>
                                {filterItems.length > 0 ? (
                                    (FilterTitle === 'Customer Name' && !customerLoading) || (FilterTitle !== 'Customer Name') ? (
                                        <List
                                            height={filterItems.length > 7 ? ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP : ITEM_HEIGHT * filterItems.length / 1.5 + ITEM_PADDING_TOP}
                                            itemCount={filterItems.length}
                                            itemSize={30}
                                            width="100%"
                                            ref={listRef}
                                        >
                                            {
                                                (isInvoiceScreen && FilterTitle === "Customer Name") ? renderCustomerRow : renderRow
                                            }
                                        </List>
                                    ) : (
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 48,
                                                marginTop: '1px'
                                            }}
                                        >
                                            <Typography variant='h3'>
                                                Loading...
                                            </Typography>
                                        </Box>
                                    )
                                ) : (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: 48,
                                            marginTop: '1px'
                                        }}
                                    >
                                        <Typography variant='h3'>
                                            No Results
                                        </Typography>
                                    </Box>
                                )}
                            </Select>
                        )
                    }
                </Card>
            </Grid>
            {
                action &&
                <CustomizedSnackbars
                    message={Message}
                    snacktype={snacktype}
                    snackstatus={action}
                    onCloseCallback={handleSnapClose}
                />
            }
        </>
    )
}

export default FilterCards
