/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Input, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { FormatUnderlined } from '@mui/icons-material';
import CustomizedSnackbars from '../snackbar';
import { stringReducer, validateInput } from '../../utils/index';
import DeleteIcon from '@mui/icons-material/Delete';

function TableItem({
    disputereason,
    disputeCodeId,
    oneditcallback,
    ondeletecallback
}) {
    const [IsEdit, SetIsEdit] = useState(false)
    const [Dispute, SetDispute] = useState({
        Id: disputeCodeId,
        Reason: disputereason
    })
    const [ODispute, SetODispute] = useState(null)
    const [Action, SetAction] = useState(false)
    const [snacktype, setSnacktype] = useState("");
    const [Message, SetMessage] = useState("")

    useEffect(() => {
        SetODispute({
            Id: disputeCodeId,
            Reason: disputereason
        })
    }, [disputeCodeId, disputereason]);

    const handleEdit = () => {
        SetIsEdit(true)
    }

    const handleDelete = () => {
        if (ondeletecallback) {
            ondeletecallback(Dispute)
        }
    }
    const handleCancel = () => {
        SetDispute(ODispute)
        SetIsEdit(false)
    }
    const handleSave = () => {
        if (Dispute.Reason === "") {
            SetAction(true)
            SetMessage('Dispute Code should not be empty.')
            setSnacktype('info')
            SetDispute(ODispute)
        }
        else {
            if (validateInput(Dispute.Reason)) {
                SetIsEdit(false)

                if (oneditcallback) {
                    oneditcallback(Dispute)
                }
            }
            else {
                SetAction(true)
                setSnacktype("error")
                SetMessage('Invalid Dispute Code.')
                SetDispute(ODispute)
            }
        }
    }
    const handleDisputeReasonChange = (event) => {
        if (event.target.value.length < 100) {
            SetDispute({
                Id: Dispute.Id,
                Reason: event.target.value
            })
        }
        else {
            SetAction(true);
            setSnacktype("warning");
            SetMessage("Dispute Code should be less than 100 characters.");
        }
    }
    const handleSnapClose = (value) => {
        SetAction(value);
    };

    return (
        <Grid
            item
            sm={12}
            lg={12}
            xs={12}
            md={12}
            xl={12}
            sx={{
                height: 40,
                borderRadius: 1,
                transition: 'background-color 0.3s',
                display: 'flex',
                marginTop: '1%',
            }}
        >
            {
                IsEdit ? (
                    <Grid
                        sx={{ display: 'flex', minWidth: '100%', maxWidth: '100%' }}>
                        <Input
                            autoFocus={true}
                            value={Dispute.Reason}
                            onChange={handleDisputeReasonChange}
                            sx={{
                                fontFamily: (theme) => theme.typography.fontFamily,
                                color: 'black',
                                minWidth: '80%', maxWidth: '80%',
                                fontSize: '14px',
                                border: '2px solid',
                                borderRadius: '5px',
                                paddingLeft: '2px',
                                fontWeight: 'bolder'
                            }}
                            inputProps={FormatUnderlined}
                            disableUnderline={true}
                        />
                        <Grid ml={4} sx={{ display: 'flex' }}>
                            <Tooltip title={'Save'} placement={'left-end'}>
                                <IconButton
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                        color: 'green',
                                    }}
                                    size="small"
                                    disableRipple
                                    onClick={handleSave}
                                >
                                    <DoneIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cancel'} placement={'right-end'}>
                                <IconButton
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                        color: 'red',
                                    }}
                                    size="small"
                                    disableRipple
                                    onClick={handleCancel}
                                >
                                    <CloseIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid sx={{ display: 'flex', alignItems: 'center', minWidth: '100%', maxWidth: '100%' }}>
                        <Grid sx={{ minWidth: '80%', maxWidth: '80%' }}>
                            {Dispute.Reason.length > 50 ?
                                <Tooltip title={Dispute.Reason} placement='bottom-start'>
                                    <Typography variant="h2" sx={{ paddingLeft: '5px' }}>
                                        {stringReducer(Dispute.Reason, 50)}
                                    </Typography>
                                </Tooltip> :
                                <Typography variant="h2" sx={{ paddingLeft: '5px' }}>
                                    {Dispute.Reason}
                                </Typography>
                            }
                        </Grid>
                        <Grid ml={4} sx={{ display: 'flex', alignItems: 'center' }}>
                            <Tooltip title={'Edit'} placement={'left-end'}>
                                <IconButton
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    }}
                                    size="small"
                                    disableRipple
                                    onClick={handleEdit}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Delete'} placement={'right-end'}>
                                <IconButton
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                    }}
                                    size="small"
                                    disableRipple
                                    onClick={handleDelete}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                )}
            {
                Action &&
                <CustomizedSnackbars
                    message={Message}
                    snacktype={snacktype}
                    snackstatus={Action}
                    onCloseCallback={handleSnapClose}
                />
            }
        </Grid>

    )
}

export default TableItem
