/* eslint-disable react/prop-types */
import React from 'react';
import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import store from './redux/store'; // Import your Redux store
import Routes from "./routes";
import { MsalProvider } from '@azure/msal-react';
import { InvoiceProvider } from '../src/context/invoiceContext';

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <Provider store={store}>
                <InvoiceProvider>
                    <ThemeProvider theme={theme}>
                        <Routes instance={instance} />
                    </ThemeProvider>
                </InvoiceProvider>
            </Provider>
        </MsalProvider>
    );
}

export default App;