/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useContext } from 'react'
import { IconButton, Card, Tooltip, Grid, Chip, Box } from '@mui/material'
import FilterCards from '../../components/filtercards';
import SearchBar from '../../components/searchbar';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { changeDateFormat, stringReducer } from '../../utils';
import { InvoiceContext } from '../../context/invoiceContext';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import { getCustomerNames, getAllDisputeCodes, getStatusName, getDivisionNames } from '../../services/api';
import Divisonmapping from '../../constants/divisionMapping.json';


function FilterLayout({
    isInvoiceScreen,
    applyfiltercallback,
    greyOut,
    searchInvoiceCallback,
    activeLabel,
    labelAppliedCallback,
    ebillingType,
    refreshCallback,
    enableFilters
}) {

    // states
    const { signedInUser } = useContext(InvoiceContext);
    const [isFilterApplied, SetIsFilterApplied] = useState(false);
    const [clearfilter, SetClearFilter] = useState(false);
    const [filterPayload, setFilterPayload] = useState({
        FromDate: '',
        ToDate: '',
        DivisionId: '',
        CustomerId: '',
        statusId: '',
        disputeCodeId: ''
    });
    const [customerInfo, setCustomerInfo] = useState([]);
    const [disputeInfo, setDisputeInfo] = useState([]);
    const [divisionInfo, setDivisionInfo] = useState([]);
    const [statusInfo, setStatusInfo] = useState([]);
    const [isRevert, setRevert] = useState(false);
    const [statusGrey, setStatusGrey] = useState(greyOut);
    const { selectedStatus, appliedFilter, appliedSearch, InitializeIndex, InitializeHighlightInvoice, InitializeNextInvoice,
        InitializeNextIndex } = useContext(InvoiceContext);
    const [blockDisputeCode, setBlockDisputeCode] = useState(false);
    const [filtercalled, setFiltercalled] = useState(false);
    const date90DaysAgo = new Date();
    date90DaysAgo.setDate(date90DaysAgo.getDate() - 360);
    const [fromDate, setFromDate] = useState(date90DaysAgo);
    const [toDate, setToDate] = useState(new Date());
    const [customerLoading, setCustomerLoading] = useState(false);
    const [isInfoHovered, setHomeHovered] = useState(false);
    const [rotate, setRotate] = useState(false);

    // functions
    useEffect(() => {
        getAllDisputeCodes().then((response) => {
            if (response) setDisputeInfo(response)
        }).catch((_err) => {
        })
        getStatusName().then((response) => {
            if (response) setStatusInfo(response)
        }).catch((_err) => {
        })
        getDivisionNames().then((response) => {
            if (response) setDivisionInfo(response)
        }).catch((_err) => {
        })
    }, []);

    useEffect(() => {
        setCustomerLoading(true);
        getCustomerNames({ billingType: ebillingType }).then((response) => {
            if (response) setCustomerInfo(response)
        }).catch((_err) => {
        }).finally(() => {
            setCustomerLoading(false);
        });
    }, [ebillingType]);

    useEffect(() => {
        if (greyOut) {
            setStatusGrey(true);
            setRevert(true);
            if (selectedStatus) {
                setFiltercalled(true);
                if ((selectedStatus.invoiceStatus !== 'Internal Dispute')
                    && (selectedStatus.invoiceStatus !== 'All')
                ) {
                    setBlockDisputeCode(true);
                }
                else {
                    setBlockDisputeCode(false);
                }
            }
        }
    }, [greyOut]);

    useEffect(() => {
        if (isInvoiceScreen && appliedFilter) {
            setFilterPayload({
                FromDate: appliedFilter.fromDate,
                ToDate: appliedFilter.toDate,
                DivisionId: appliedFilter.division === null ? '' : appliedFilter.division,
                CustomerId: appliedFilter.customerId.length === 0 ? [] : appliedFilter.customerId,
                statusId: appliedFilter.statusId === null ? '' : appliedFilter.statusId,
                disputeCodeId: appliedFilter.disputeCodeId === null ? '' : appliedFilter.disputeCodeId
            });
            if ((appliedFilter.statusId !== 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522')
                && (appliedFilter.statusId !== null)
            ) {
                setBlockDisputeCode(true);
            }
            else {
                setBlockDisputeCode(false);
            }
            if (appliedFilter.division === null &&
                appliedFilter.customerId === null &&
                appliedFilter.statusId === null &&
                appliedFilter.disputeCodeId === null) {
                setRevert(false);
            }
            else {
                setRevert(true);
                setFiltercalled(true);
            }
        }
    }, []);



    const handleFilter = (value) => {
        if (value) {
            SetIsFilterApplied(value);
            SetClearFilter(!value);
        }
    }

    const handleFilterCancel = () => {
        if (filtercalled) {
            SetClearFilter(true);
            setFiltercalled(false);
            setStatusGrey(false);
            SetIsFilterApplied(false);
            setBlockDisputeCode(false);
            setRevert(false);
            const emptyFilterPayload = {
                ...filterPayload,
                FromDate: '',
                ToDate: '',
                DivisionId: '',
                CustomerId: '',
                statusId: '',
                disputeCodeId: ''
            };
            setFilterPayload(emptyFilterPayload)
            if (applyfiltercallback) {
                applyfiltercallback(emptyFilterPayload, true)
            }
        }
        else {
            SetClearFilter(true);
            setFiltercalled(false);
            SetIsFilterApplied(false);
            setBlockDisputeCode(false);
            setFilterPayload({
                FromDate: '',
                ToDate: '',
                DivisionId: '',
                CustomerId: '',
                statusId: '',
                disputeCodeId: ''
            })
        }
    }

    const handleFilterApply = () => {
        SetIsFilterApplied(false);
        setFiltercalled(true);
        setRevert(true);
        InitializeIndex();
        InitializeHighlightInvoice();
        InitializeNextInvoice();
        InitializeNextIndex(0);
        if (applyfiltercallback) {
            applyfiltercallback(filterPayload, false)
        }
    }

    const handleRevert = () => {
        SetClearFilter(true);
        setFromDate(date90DaysAgo);
        setToDate(new Date());
        setStatusGrey(false);
        SetIsFilterApplied(false);
        setBlockDisputeCode(false);
        setRevert(false);
        InitializeIndex();
        InitializeHighlightInvoice();
        InitializeNextInvoice();
        InitializeNextIndex(0);
        const emptyFilterPayload = {
            ...filterPayload,
            FromDate: '',
            ToDate: '',
            DivisionId: '',
            CustomerId: '',
            statusId: '',
            disputeCodeId: ''
        };
        setFilterPayload(emptyFilterPayload);
        if (applyfiltercallback) {
            applyfiltercallback(emptyFilterPayload, true);
        }
    }

    // Search functions
    const handleSearchApply = (value) => {
        if (searchInvoiceCallback) {
            if (appliedSearch) {
                searchInvoiceCallback(appliedSearch);
            }
            else {
                searchInvoiceCallback(value);
            }
        }
    }

    const constructFilterPayload = (value, Title) => {
        const updatedPayload = { ...filterPayload };
        if (Title === "Division" && value.Name === "All") {
            value.Name = null;
        }
        if (selectedStatus) {
            if (Title === "Status") {
                updatedPayload.statusId = value.Id;
                if (value.Name !== 'Internal Dispute' && value.Name !== 'All') {
                    setBlockDisputeCode(true);
                    updatedPayload.disputeCodeId = '';
                }
                else {
                    setBlockDisputeCode(false);
                }
            }
            else {
                updatedPayload.statusId = selectedStatus.currentStatusId;
            }
        }
        if (Title === 'Status') {
            if ((value.Name !== 'Internal Dispute') && (value.Name !== 'All')) {
                setBlockDisputeCode(true);
                updatedPayload.disputeCodeId = '';
            }
            else {
                setBlockDisputeCode(false);
            }
            updatedPayload.statusId = value.Id;
        }
        if (Title === 'Customer Name') {
            if (isInvoiceScreen) {
                if (!updatedPayload.CustomerId) {
                    updatedPayload.CustomerId = [];
                }
                if (updatedPayload.CustomerId.includes(value.Id)) {
                    updatedPayload.CustomerId = updatedPayload.CustomerId.filter(id => id !== value.Id);
                } else {
                    updatedPayload.CustomerId = [...updatedPayload.CustomerId, value.Id];
                }
            }
            else {
                updatedPayload.CustomerId = value.Id;
            }
        }
        switch (Title) {
            case 'From Date':
                updatedPayload.FromDate = changeDateFormat(value);
                break;
            case 'To Date':
                updatedPayload.ToDate = changeDateFormat(value);
                break;
            case 'Division':
                updatedPayload.DivisionId = value.Name;
                break;
            case 'Dispute Code':
                updatedPayload.disputeCodeId = value.Id;
                break;
            default:
                break;
        }
        if (updatedPayload.FromDate === '' &&
            updatedPayload.ToDate === '' &&
            updatedPayload.DivisionId === '' &&
            updatedPayload.CustomerId.length === 0 &&
            updatedPayload.statusId === '' &&
            updatedPayload.disputeCodeId === '') {
            SetIsFilterApplied(false);
            SetClearFilter(true);
        }
        setFilterPayload(updatedPayload);
    };

    const handleLabelRemove = () => {
        if (labelAppliedCallback) {
            labelAppliedCallback("");
        }
    }

    const handleRefresh = () => {
        setRotate(true);
        if (refreshCallback) {
            refreshCallback();
        }
        setTimeout(() => setRotate(false), 1000);
    }

    const findMappingDivisionName = (Divisonmapping, groups) => {
        for (const group of groups) {
            const found = Divisonmapping.find(item => item.groupId === group);
            if (found) {
                return found.mappingDivisonName;
            }
        }
        return null;
    };

    return (
        <Grid
            container
            sx={{
                marginTop: '5px',
                alignItems: 'center',
                gap: "10px",
            }}
        >
            <FilterCards
                FilterTitle={"From Date"}
                filterappliedcallback={handleFilter}
                clearfilter={clearfilter}
                IsDateFilter={true}
                selectedfiltercallback={constructFilterPayload}
                filterTo={toDate}
                setDateCallback={setFromDate}
                isInvoiceScreen={isInvoiceScreen}
                enableFilters={enableFilters}
            />
            <FilterCards
                FilterTitle={"To Date"}
                filterappliedcallback={handleFilter}
                clearfilter={clearfilter}
                IsDateFilter={true}
                selectedfiltercallback={constructFilterPayload}
                filterFrom={fromDate}
                setDateCallback={setToDate}
                isInvoiceScreen={isInvoiceScreen}
                enableFilters={enableFilters}
            />
            {
                ((signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) ||
                    findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) === "" ||
                    findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) === null)
                &&
                <FilterCards
                    FilterTitle={"Division"}
                    filterappliedcallback={handleFilter}
                    clearfilter={clearfilter}
                    IsDateFilter={false}
                    FilterData={divisionInfo}
                    isInvoiceScreen={isInvoiceScreen}
                    selectedfiltercallback={constructFilterPayload}
                    enableFilters={enableFilters}
                />
            }
            <FilterCards
                FilterTitle={"Customer Name"}
                filterappliedcallback={handleFilter}
                clearfilter={clearfilter}
                IsDateFilter={false}
                FilterData={customerInfo}
                selectedfiltercallback={constructFilterPayload}
                customerLoading={customerLoading}
                isInvoiceScreen={isInvoiceScreen}
                enableFilters={enableFilters}
            />
            {
                isInvoiceScreen &&
                (
                    statusGrey ? (
                        <FilterCards
                            FilterTitle={"Status"}
                            passedStatus={statusGrey}
                            filterappliedcallback={handleFilter}
                            clearfilter={clearfilter}
                            IsDateFilter={false}
                            FilterData={statusInfo}
                            selectedfiltercallback={constructFilterPayload}
                            isInvoiceScreen={isInvoiceScreen}
                            enableFilters={enableFilters}
                        />
                    ) : (
                        (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) &&
                        <FilterCards
                            FilterTitle={"Status"}
                            filterappliedcallback={handleFilter}
                            clearfilter={clearfilter}
                            IsDateFilter={false}
                            FilterData={statusInfo}
                            selectedfiltercallback={constructFilterPayload}
                            isInvoiceScreen={isInvoiceScreen}
                            enableFilters={enableFilters}
                        />

                    )
                )
            }
            {
                isInvoiceScreen &&
                (
                    blockDisputeCode ? (
                        <Grid
                            item
                            xs={12}
                            sm={1.2}
                            md={1.2}
                            lg={1.2}
                        >
                            <Card
                                sx={{
                                    display: 'flex',
                                    height: 25,
                                    fontSize: 12,
                                    backgroundColor: 'grey',
                                    color: 'white',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}>
                                Dispute Code
                            </Card>
                        </Grid>
                    ) :
                        <FilterCards
                            FilterTitle={"Dispute Code"}
                            filterappliedcallback={handleFilter}
                            clearfilter={clearfilter}
                            IsDateFilter={false}
                            FilterData={disputeInfo}
                            selectedfiltercallback={constructFilterPayload}
                            isInvoiceScreen={isInvoiceScreen}
                            enableFilters={enableFilters}
                        />
                )
            }
            {
                isFilterApplied ? (
                    <Grid
                        item
                        xs={12}
                        sm={0.6}
                        md={0.6}
                        lg={0.6}
                    >
                        <Card sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            height: 25,
                            fontSize: 12,
                            color: 'white'
                        }}>
                            <Tooltip title="Apply">
                                <IconButton
                                    onClick={handleFilterApply}
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                        color: 'green'
                                    }}
                                    size="small"
                                    disableRipple
                                >
                                    <DoneIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Clear">
                                <IconButton
                                    onClick={handleFilterCancel}
                                    sx={{
                                        '&.MuiButtonBase-root:hover': {
                                            bgcolor: 'transparent',
                                        },
                                        color: 'red'
                                    }}
                                    size="small"
                                    disableRipple
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Tooltip>
                        </Card>
                    </Grid>
                ) : (
                    isRevert ? (
                        <Grid
                            item
                            xs={12}
                            sm={0.6}
                            md={0.6}
                            lg={0.6}
                        >
                            <Card sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: 35,
                                height: 25,
                                fontSize: 12,
                                color: 'white'
                            }}>
                                <Tooltip title="Clear">
                                    <IconButton
                                        onClick={handleRevert}
                                        sx={{
                                            '&.MuiButtonBase-root:hover': {
                                                bgcolor: 'transparent',
                                            },
                                            color: 'red'
                                        }}
                                        size="small"
                                        disableRipple
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </Tooltip>
                            </Card>
                        </Grid>) : (
                        <Grid
                            item
                            xs={12}
                            sm={0.6}
                            md={0.6}
                            lg={0.6}
                            sx={{
                                height: 25
                            }}
                        />)
                )
            }
            {
                (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) &&
                Array(1).fill(null).map((_, index) => (
                    <Grid
                        item
                        key={index}
                        xs={12}
                        sm={1.2}
                        md={1.2}
                        lg={1.2}
                        sx={{
                            height: 25
                        }}
                    />
                ))
            }
            <Grid
                item
                xs={12}
                sm={0.6}
                md={0.6}
                lg={0.6}
                sx={{
                    height: 25
                }}
            >
                {
                    (activeLabel) &&
                    <Chip
                        size='small'
                        sx={{
                            fontSize: 12,
                            height: 25,
                            cursor: 'pointer',
                            color: 'white',
                            backgroundColor: (theme) => theme.palette.primary.main
                        }}
                        label={stringReducer(activeLabel, 8)}
                        onDelete={handleLabelRemove}
                    />
                }
            </Grid>
            {
                isInvoiceScreen &&
                <Grid
                    item
                    xs={12}
                    sm={2.4}
                    md={2.4}
                    lg={2.4}
                    sx={{
                        height: 25,
                        marginLeft: 'auto',
                        display: 'flex',
                        justifyContent: 'space-around'
                    }}
                >
                    <Tooltip title="Refresh">
                        <Card
                            sx={{
                                borderRadius: "20px",
                                width: '15%',
                                height: 25,
                                backgroundColor: (theme) => theme.palette.primary.main,
                                color: 'white',
                                display: 'flex',
                                justifyContent: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleRefresh}
                        >
                            <AutorenewOutlinedIcon
                                sx={{
                                    animation: rotate ? 'rotate 1s linear' : 'none',
                                }}
                            />
                            <style>
                                {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
                            </style>
                        </Card>
                    </Tooltip>
                    <Card
                        sx={{
                            borderRadius: "20px",
                            width: '80%',
                            height: 25,
                            backgroundColor: (theme) => theme.palette.primary.main,
                            "&::placeholder": {
                                textAlign: "center",
                            },
                        }}
                    >
                        <SearchBar
                            searchApplyCallBack={handleSearchApply}
                            placeholder="Search by Invoice Number"
                            isCrossIcon={true}
                            isSerachIcon={true}
                            isAutoFocus={false}
                            isInvoiceList={true}
                            textColor="white"
                        />
                    </Card>
                </Grid>
            }
            {
                !isInvoiceScreen &&
                <Grid
                    item
                    xs={12}
                    sm={0.3}
                    md={0.3}
                    lg={0.3}
                    sx={{
                        height: 25,
                        marginLeft: 'auto'
                    }}
                >
                    <Box
                        onMouseEnter={() => setHomeHovered(true)}
                        onMouseLeave={() => setHomeHovered(false)}
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer'
                        }}
                    >
                        {
                            isInfoHovered ?
                                <Tooltip title="The Dashboard screen contains metrics corrresponding to EB records only." placement='left-start'>
                                    <InfoIcon
                                        sx={{
                                            color: '#2196f3'
                                        }}
                                    />
                                </Tooltip>
                                :
                                <InfoOutlinedIcon
                                    sx={{
                                        color: '#2196f3'
                                    }}
                                />
                        }
                    </Box>
                </Grid>
            }
        </Grid>
    )
}

export default FilterLayout
