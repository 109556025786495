/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { Box, IconButton, Grid, Menu, MenuItem, Tooltip } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import tableheading from '../../constants/tableHeading.json';
import GetAppIcon from '@mui/icons-material/GetApp';

function InvoiceTableHeader({
    sortApplyCallback,
    exportCallback,
    signedInUser,
    isCompleted
}) {

    const [heading, setHeading] = useState(tableheading);
    const [currentSortColumn, setCurrentSortColumn] = useState({
        heading: "Last Modified",
        sortVariable: false
    });
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (isCompleted) {
            heading[4].isSortEnabled = false;
        }
        else {
            heading[4].isSortEnabled = true;
        }
    }, [isCompleted])

    const handleSort = (val) => {
        setCurrentSortColumn({
            heading: val.heading,
            sortVariable: val.sortVariable
        })
        const updatedHeadings = heading.map((head) => {
            if (head.sortheader === val.sortheader) {
                if (sortApplyCallback) {
                    if (val.sortheader === 'InvoiceAge') {
                        sortApplyCallback('InvoiceDateTime', !head.sortVariable);
                    }
                    else {
                        sortApplyCallback(head.sortheader, head.sortVariable);
                    }
                }
                return {
                    ...head,
                    sortVariable: !head.sortVariable
                };
            }
            return head;
        });
        setHeading(updatedHeadings);
    };

    const handleExport = (val) => {
        if (exportCallback) {
            exportCallback(val);
        }
        setAnchorEl(null);
    }

    return (
        <Grid
            item
            xs={12} sm={12} md={12} lg={12}
            sx={{
                height: '8%',
                display: 'flex',
                marginTop: '5px',
                borderBottom: '0.5px solid #e0e0e0'
            }}
        >
            {
                heading.map((item, index) => (
                    item.heading === "Labels" || item.heading === "Invoice Status" ?
                        signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                        <Grid
                            item
                            key={index}
                            sx={{
                                backgroundColor: 'white',
                                width: item.wsize,
                                height: '100%',
                                fontSize: 10,
                                fontWeight: 'bold',
                                padding: '8px',
                                position: 'relative'  // Add this to ensure relative positioning for the cell
                            }}
                            align='center'
                        >
                            <Box sx={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                justifyContent: 'center',
                                alignItems: 'center',
                                '&:hover .hover-icon': {
                                    opacity: 1,
                                },
                                '&:hover .sort-desc-icon, &:hover .sort-asc-icon': {
                                    opacity: 0
                                },
                                cursor: item.isSortEnabled ? 'pointer' : 'inherit'
                            }}
                                onClick={() => {
                                    if (item.isSortEnabled)
                                        handleSort(item)
                                }}
                            >
                                {item.heading}
                                {
                                    item.isSortEnabled && (
                                        <IconButton
                                            size='small'
                                            disableRipple={true}
                                            className="hover-icon"
                                            sx={{
                                                opacity: 0,
                                                transition: 'opacity 0.3s',
                                                position: 'absolute',
                                                right: 0,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                marginLeft: 1,
                                                cursor: 'pointer'
                                            }}

                                        >
                                            {
                                                item.sortVariable ?
                                                    <ArrowUpwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                                    :
                                                    <ArrowDownwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                            }

                                        </IconButton>
                                    )
                                }
                                {
                                    (item.isSortEnabled && item.heading === currentSortColumn.heading && currentSortColumn.sortVariable) &&
                                    <IconButton
                                        size='small'
                                        disableRipple={true}
                                        className="sort-asc-icon"
                                        sx={{
                                            opacity: 1,
                                            transition: 'opacity 0.3s',
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                            marginLeft: 1
                                        }}
                                        onClick={() => {
                                            handleSort(item)
                                        }}
                                    >
                                        <ArrowUpwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                    </IconButton>
                                }
                                {
                                    (item.isSortEnabled && item.heading === currentSortColumn.heading && currentSortColumn.sortVariable === false) && (
                                        <IconButton
                                            size='small'
                                            disableRipple={true}
                                            className="sort-desc-icon"
                                            sx={{
                                                opacity: 1,
                                                transition: 'opacity 0.3s',
                                                position: 'absolute',
                                                right: 0,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                marginLeft: 1
                                            }}
                                            onClick={() => {
                                                handleSort(item)
                                            }}
                                        >
                                            <ArrowDownwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                        </IconButton>
                                    )
                                }
                            </Box>
                        </Grid>
                        :
                        item.heading === 'Export' ?
                            <Grid
                                item
                                key={index}
                                sx={{
                                    backgroundColor: 'white',
                                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ?
                                        item.wsize.treasury : item.wsize.regional,
                                    height: '100%',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    padding: '0px',
                                    position: 'relative',
                                    display: 'flex',
                                    // Add this to ensure relative positioning for the cell
                                    alignItems: 'center'
                                }}
                                align='center'
                            >
                                <Tooltip title="Export Data">
                                    <GetAppIcon
                                        sx={{
                                            fontSize: '15px',
                                            cursor: 'pointer'
                                        }}
                                        onClick={
                                            handleClick
                                        }
                                    />
                                </Tooltip>
                                {
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={open}
                                        onClose={handleClose}
                                        PaperProps={{
                                            style: {
                                                backgroundColor: 'aliceblue',
                                            },
                                        }}
                                    >
                                        <MenuItem
                                            key="0"
                                            onClick={() => { handleExport(0) }}
                                            sx={{
                                                fontSize: 10
                                            }}
                                        >
                                            Export Selection
                                        </MenuItem>
                                        <MenuItem
                                            key="1"
                                            onClick={() => { handleExport(1) }}
                                            sx={{
                                                fontSize: 10
                                            }}
                                        >
                                            Export All
                                        </MenuItem>
                                    </Menu>
                                }
                            </Grid>
                            :
                            item.placeholder ?
                            signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                            <Grid
                                item
                                key={index}
                                sx={{
                                    backgroundColor: 'white',
                                    width: item.wsize,
                                    height: '100%',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    padding: '8px',
                                    position: 'relative'  // Add this to ensure relative positioning for the cell
                                }}
                                align='center'
                            >
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >                                    
                                </Box>

                            </Grid>
                            :
                            <Grid
                                item
                                key={index}
                                sx={{
                                    backgroundColor: 'white',
                                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ?
                                        item.wsize.treasury : item.wsize.regional,
                                    height: '100%',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    padding: '8px',
                                    position: 'relative'  // Add this to ensure relative positioning for the cell
                                }}
                                align='center'
                            >
                                <Box sx={{
                                    display: 'flex',
                                    width: '100%',
                                    height: '100%',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    '&:hover .hover-icon': {
                                        opacity: 1,
                                    },
                                    '&:hover .sort-desc-icon, &:hover .sort-asc-icon': {
                                        opacity: 0
                                    },
                                    cursor: item.isSortEnabled ? 'pointer' : 'inherit'
                                }}
                                    onClick={() => {
                                        if (item.isSortEnabled)
                                            handleSort(item)
                                    }}
                                >
                                    {item.heading}
                                    {
                                        item.isSortEnabled && (
                                            <IconButton
                                                size='small'
                                                disableRipple={true}
                                                className="hover-icon"
                                                sx={{
                                                    opacity: 0,
                                                    transition: 'opacity 0.3s',
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    marginLeft: 1,
                                                    cursor: 'pointer'
                                                }}
                                            >
                                                {
                                                    item.sortVariable ?
                                                        <ArrowUpwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                                        :
                                                        <ArrowDownwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                                }
                                            </IconButton>
                                        )
                                    }
                                    {
                                        (item.isSortEnabled && item.heading === currentSortColumn.heading && currentSortColumn.sortVariable) &&
                                        <IconButton
                                            size='small'
                                            disableRipple={true}
                                            className="sort-asc-icon"
                                            sx={{
                                                opacity: 1,
                                                transition: 'opacity 0.3s',
                                                position: 'absolute',
                                                right: 0,
                                                top: '50%',
                                                transform: 'translateY(-50%)',
                                                marginLeft: 1
                                            }}
                                            onClick={() => {
                                                handleSort(item)
                                            }}
                                        >
                                            <ArrowUpwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                        </IconButton>
                                    }
                                    {
                                        (item.isSortEnabled && item.heading === currentSortColumn.heading && currentSortColumn.sortVariable === false) && (
                                            <IconButton
                                                size='small'
                                                disableRipple={true}
                                                className="sort-desc-icon"
                                                sx={{
                                                    opacity: 1,
                                                    transition: 'opacity 0.3s',
                                                    position: 'absolute',
                                                    right: 0,
                                                    top: '50%',
                                                    transform: 'translateY(-50%)',
                                                    marginLeft: 1
                                                }}
                                                onClick={() => {
                                                    handleSort(item)
                                                }}
                                            >
                                                <ArrowDownwardIcon sx={{ width: '70%', fontSize: '15px' }} />
                                            </IconButton>
                                        )
                                    }
                                </Box>
                            </Grid>
                ))
            }

        </Grid>
    )
}

export default InvoiceTableHeader


