import api from './client';

// Get All Dispute Codes
export const getAllDisputeCodes = () => {
    return api
        .get(`DisputeCodes`)
        .then(payload => {
            return payload;
        });
}

// Create new Dispute Code
export const createNewDisputeCode = (payload) => {
    return api
        .post(`DisputeCodes`, payload)
        .then(payload => {
            return payload;
        });
}

// Edit Existing Dispute Code
export const editDisputeCode = (payload) => {
    return api
        .put(`DisputeCodes`, payload)
        .then(payload => {
            return payload;
        });
}

// Delete Existing Dispute Code
export const deleteDisputeCode = (payload) => {
    return api
        .put(`DisputeCodes/set-inactive`, payload)
        .then(payload => {
            return payload;
        });
}

// Get All Customer Names
export const getCustomerNames = (payload) => {
    return api
        .post(`Customers/customer-names`, payload)
        .then(payload => {
            return payload;
        });
}

// Get All Division Names
export const getDivisionNames = () => {
    return api
        .get(`Company/divisions`)
        .then(payload => {
            return payload;
        });
}

// Get All Status Names
export const getStatusName = () => {
    return api
        .get(`InvoiceStatus`)
        .then(payload => {
            return payload;
        });
}

// Get All Invoices/ Invoice Listing Api
export const getAllInvoices = (payload) => {
    return api
        .post(`Invoices/invoices-list`, payload)
        .then(payload => {
            return payload;
        });
}

// Get Invoices Amount and Count Api
export const getInvoicesCount = (payload) => {
    return api
        .post(`Invoices/total`, payload)
        .then(payload => {
            return payload;
        });
}

// Get Invoice details Api
export const getInvoiceDetails = (invoiceNumber) => {
    return api
        .get('Invoices/' + invoiceNumber)
        .then(payload => {
            return payload;
        });
}

// Get Invoice activity Api
export const getInvoiceActivity = (payload) => {
    return api
        .post('InvoiceActivity/activity-list', payload)
        .then(payload => {
            return payload;
        });
}

// Get Add comment Api
export const addComment = (payload) => {
    return api
        .post('InvoiceActivity', payload)
        .then(payload => {
            return payload;
        });
}

// Add or Update Invoice Labels
export const updateInvoiceLabel = (payload) => {
    return api
        .put(`Invoices/update-labels`, payload)
        .then(payload => {
            return payload;
        });
}

// Change or Update Invoice Status
export const updateStatus = (payload) => {
    return api
        .post('InvoiceActivity/update-status', payload)
        .then(payload => {
            return payload;
        });
}

// Get KPI Cards Data
export const getCardData = (payload) => {
    return api
        .post('Invoices/card-data', payload)
        .then(payload => {
            return payload;
        })
}

// Get KPI Charts Data
export const getChartData = (payload) => {
    return api
        .post('Invoices/chart-data', payload)
        .then(payload => {
            return payload;
        })
}

// Get KPI Dispute Volume Data
export const getDisputeVolumeData = (payload) => {
    return api
        .post('Invoices/dispute-volume', payload)
        .then(payload => {
            return payload;
        })
}

// Get KPI average-dispute
export const getAverageDispute = (payload) => {
    return api
        .post('Invoices/average-dispute', payload)
        .then(payload => {
            return payload;
        })
}

// Get Dispute Codes List
export const getDisputeCodesList = (payload) => {
    return api
        .post(`DisputeCodes/list`, payload)
        .then(payload => {
            return payload;
        });
}

// Get Index
export const getSelectedIndex = (payload) => {
    return api
        .post(`Invoices/selected-index`, payload)
        .then(payload => {
            return payload;
        });
}

// Export Invoice List To Excel
export const invoicesExport = (payload) => {
    return api
        .post(`Invoices/invoices-export`, payload)
        .then(payload => {
            return payload;
        });
}