/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react'
import {
    Box, IconButton,
    Tooltip, MenuItem, Menu,
    Link, Grid
} from '@mui/material'
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import { stringReducer } from '../../utils';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import { InvoiceContext } from '../../context/invoiceContext';
import { useNavigate } from 'react-router-dom';
// Data Imports
import StatusData from '../../constants/Status_Data.json';
import StatusMapping from '../../constants/statusMapping.json';
import StatusMappingEA from '../../constants/statusMappingEA.json';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';

import './style.css';
function InvoiceTableItem({
    invoicedata,
    statuspayloadcallback,
    statusapplycallback,
    labeleditcallback,
    labelfiltercallback,
    ebillType,
    onSelect,
    isSelected
}) {
    const [statusData, setStatusData] = useState(StatusData);
    const [anchorEl, setAnchorEl] = useState(null);
    const [invoiceInfo, setInvoiceInfo] = useState(invoicedata);
    const [isCopied, setIsCopied] = useState(false);
    const [copiedInvoice, setCopiedInvoice] = useState(null);

    const open = Boolean(anchorEl);

    const { signedInUser } = useContext(InvoiceContext);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        constructStatus();
        setInvoiceInfo(invoicedata);
    }, [invoicedata]);

    const constructStatus = () => {
        if (ebillType === 'EB') {
            StatusMapping.forEach((Istatus) => {
                if (Istatus.invStatus.statusId === invoicedata.statusId) {
                    setStatusData(Istatus.Mapping);
                }
            })
        }
        else {
            StatusMappingEA.forEach((Istatus) => {
                if (Istatus.invStatus.statusId === invoicedata.statusId) {
                    setStatusData(Istatus.Mapping);
                }
            })
        }
    }

    const handleStatusChange = (event) => {
        if (statuspayloadcallback) {
            statuspayloadcallback(event.target.selected, invoicedata);
            setAnchorEl(null);
        }
    };

    const [chipData, setChipData] = useState(invoicedata.invoiceLabels);

    useEffect(() => {
        setChipData(invoicedata?.invoiceLabels)
    }, [invoicedata])

    const handlelableedit = () => {
        if (labeleditcallback) {
            labeleditcallback(true, invoicedata)
        }
    }

    const handleInvoiveDetail = () => {
        navigate(`/invoice/${invoiceInfo.invoiceNumber}`);
    }

    const handleCopy = (invoiceNumber) => {
        navigator.clipboard.writeText(invoiceNumber);
        setIsCopied(true);
        setCopiedInvoice(invoiceNumber);
        setTimeout(() => {
            setIsCopied(false);
            setCopiedInvoice(null);
        }, 1000);
    }

    const handleLabelClick = (data) => {
        if (labelfiltercallback) {
            labelfiltercallback(data);
        }
    }

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'gainsboro'
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: 'gainsboro'
        }
    }));

    return (
        <Grid
            item
            xs={12} sm={12} md={12} lg={12}
            sx={{
                display: 'flex',
                borderBottom: '0.5px solid #e0e0e0',
                backgroundColor: isSelected ? '#c4ddf2' : 'inherit',
            }}
            onClick={onSelect}
        >
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '10.5%' : '13%',
                    padding: '8px'
                }}
                align='center'
            >
                <Box sx={{ width: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Tooltip title={invoiceInfo.invoiceNumber}>
                        <Link underline="always" sx={{ cursor: 'pointer' }} onClick={() => { handleInvoiveDetail() }}>
                            {
                                (invoiceInfo.invoiceNumber.length > 11) ?
                                    stringReducer(invoiceInfo.invoiceNumber, 9) : invoiceInfo.invoiceNumber
                            }
                        </Link>
                    </Tooltip>
                    <Tooltip title={isCopied && copiedInvoice === invoiceInfo.invoiceNumber ? 'Copied' : 'Copy'}>
                        <Box sx={{
                            width: '15%',
                            '&:hover .copy-icon': {
                                opacity: 1
                            },
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                handleCopy(invoiceInfo.invoiceNumber)
                            }}
                        >
                            {
                                isCopied && copiedInvoice === invoiceInfo.invoiceNumber ?
                                    <DoneIcon className='copy-icon' sx={{ width: '70%', fontSize: '15px', opacity: 0, transition: 'opacity 0.3s' }} />
                                    :
                                    <ContentCopyIcon className='copy-icon' sx={{ width: '70%', fontSize: '15px', opacity: 0, transition: 'opacity 0.3s' }} />
                            }
                        </Box>
                    </Tooltip>
                </Box>
            </Grid>
            {
                signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                <Grid
                    item
                    sx={{
                        fontSize: 11,
                        width: '10%',
                        padding: '8px'
                    }}
                    align='center'
                >
                    <Box sx={{ width: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Tooltip title={invoiceInfo.invoiceStatus}>
                            <span className='inv-status' style={{ textAlign: 'left' }}>{invoiceInfo.invoiceStatus}</span>
                        </Tooltip>
                    </Box>
                </Grid>
            }
            {
                signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                <Grid
                    item
                    sx={{
                        fontSize: 11,
                        width: '2%',
                        padding: '4px',
                        paddingTop: '8px',
                        paddingBottom: '8px'
                    }}
                    align='center'
                >
                    <Box sx={{ width: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {
                            (invoiceInfo.isInternalDisputeResolved) ? (
                                <Tooltip title="High Priority">
                                    <Box sx={{ width: '100%', marginLeft: '2%' }}>
                                        <WarningIcon sx={{ width: '100%', color: 'red', fontSize: '12px', verticalAlign: 'top' }} />
                                    </Box>
                                </Tooltip>
                            )
                                :
                                (
                                    (invoiceInfo.isPriority) &&
                                    <Tooltip title="Medium Priority">
                                        <Box sx={{ width: '100%', marginLeft: '2%' }}>
                                            <WarningAmberIcon sx={{ width: '100%', color: 'rgb(239, 152, 0)', fontSize: '12px', verticalAlign: 'top' }} />
                                        </Box>
                                    </Tooltip>

                                )
                        }
                    </Box>
                </Grid>
            }
            <Grid
                item
                sx={{
                    fontSize: 11,
                    minWidth: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '8.5%' : '11.5%',
                    padding: '8px'
                }}
                align='center'
            >
                {invoiceInfo.invoiceDate}
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    minWidth: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '8.5%' : '11.5%',
                    padding: '8px'
                }}
                align='center'
            >
                <Tooltip
                    title={
                        invoiceInfo.invoiceAmount
                            .toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                    }>
                    {stringReducer
                        (invoiceInfo.invoiceAmount
                            .toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })
                            , 10)
                    }
                </Tooltip>
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '8%' : '11%',
                    padding: '8px'
                }}
                align='center'
            >
                {invoiceInfo.invoiceAge}
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '7%' : '10%',
                    padding: '8px'
                }}
                align='center'
            >
                <Tooltip title={invoiceInfo.division}>
                    {stringReducer(invoiceInfo.division, signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? 12 : 15)}
                </Tooltip>
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '10.5%' : '13.5%',
                    padding: '8px'
                }}
                align='center'
            >
                <Tooltip title={invoiceInfo.customerName}>
                    {stringReducer(invoiceInfo.customerName, signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? 15 : 20)}
                </Tooltip>
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '9.5%' : '12.5%',
                    padding: '8px'
                }}
                align='center'
            >
                <Tooltip title={invoiceInfo.disputeCode}>
                    {stringReducer(invoiceInfo.disputeCode, 10)}
                </Tooltip>
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? '9.5%' : '12.5%',
                    padding: '8px'
                }}
                align='center'
            >
                {invoiceInfo.modifiedOn}
            </Grid>
            {
                signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                <Grid
                    item
                    sx={{
                        width: '13%',
                        fontSize: 11,
                        padding: '8px'
                    }}
                    align='center'
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        {chipData.map((data, index) => {
                            if (index < 2) {
                                return (
                                    <>
                                        <Tooltip title={data}>
                                            <Chip
                                                key={index}
                                                size='small'
                                                sx={{
                                                    marginLeft: 1,
                                                    fontSize: 11,
                                                    cursor: 'pointer',
                                                }}
                                                label={stringReducer(data, 5)}
                                                onClick={() => { handleLabelClick(data) }}
                                            />
                                        </Tooltip>
                                    </>
                                );
                            }
                            return (<></>)
                        })}
                        {
                            (chipData.length > 2) && (
                                <LightTooltip
                                    placement='top-start'
                                    title={
                                        <Box style={{ maxWidth: '300px', overflow: 'auto', padding: '1px' }}>
                                            {chipData.map((data, index) => (
                                                index > 1 ? (
                                                    <Chip
                                                        key={index}
                                                        size="small"
                                                        sx={{
                                                            fontSize: '10px',
                                                            color: 'black',
                                                            backgroundColor: '#ebebeb',
                                                            cursor: 'pointer',
                                                            display: 'block',
                                                            marginBottom: '4px',
                                                        }}
                                                        onClick={() => { handleLabelClick(data) }}
                                                        label={data}
                                                    />
                                                ) : null
                                            ))}
                                        </Box>
                                    }
                                >
                                    <Link
                                        sx={{ marginLeft: 1, fontSize: 11, cursor: 'pointer', color: 'rgb(33 150 243)' }}
                                        underline='none'
                                    >
                                        {`+${chipData.length - 2}`}
                                    </Link>
                                </LightTooltip>
                            )
                        }
                        <Box sx={{ flexGrow: 1 }} />
                        {
                            (ebillType === 'EB') ?
                                (chipData.length > 0) ?
                                    <Tooltip title="Edit">
                                        <IconButton size='small'
                                            sx={{
                                                '&.MuiButtonBase-root:hover': {
                                                    bgcolor: 'transparent',
                                                }
                                            }}
                                            disableRipple
                                            onClick={handlelableedit}
                                        >
                                            <EditIcon style={{ fontSize: '15px' }} />
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="Add">
                                        <IconButton size='small'
                                            sx={{
                                                '&.MuiButtonBase-root:hover': {
                                                    bgcolor: 'transparent',
                                                }
                                            }}
                                            disableRipple
                                            onClick={handlelableedit}
                                        >
                                            <AddCircleOutlineIcon style={{ fontSize: '15px' }} />
                                        </IconButton>
                                    </Tooltip>
                                :
                                <></>
                        }
                    </Box>
                </Grid>
            }
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: '2%',
                    padding: '2px',
                    alignContent: 'space-evenly'
                }}
                align='center'
            >
                {
                    invoiceInfo.invoiceSPLink &&
                    <Tooltip title='SharePoint List'>
                        <a target='blank' href={invoiceInfo.invoiceSPLink}>
                            <LinkIcon style={{ fontSize: '15px', color: 'rgb(33 150 243)', verticalAlign: 'middle' }} />
                        </a>
                    </Tooltip>
                }
            </Grid>
            <Grid
                item
                sx={{
                    fontSize: 11,
                    width: '2%',
                    padding: '2px',
                    alignContent: 'space-evenly'
                }}
                align='center'>
                {
                    (invoiceInfo.invoiceStatus !== "Completed") &&
                    <Tooltip title='Change Status' placement='left-end'>
                        <MoreVertIcon style={{ fontSize: '15px', cursor: 'pointer' }} onClick={handleClick} />
                    </Tooltip>
                }
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                        style: {
                            backgroundColor: 'white',
                            boxShadow: 'none',
                        },
                    }}
                >
                    {
                        (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) ?
                            statusData.map((indiStatus) => (
                                <MenuItem
                                    key={indiStatus.statusId}
                                    selected={
                                        { Id: indiStatus.statusId, Name: indiStatus.statusName }
                                    }
                                    onClick={handleStatusChange}
                                    sx={{
                                        fontSize: 11
                                    }}
                                >
                                    {indiStatus.statusName}
                                </MenuItem>
                            )) :
                            (
                                <MenuItem
                                    key="2d2f50a8-70b1-4848-8ed7-01f14e12fee4"
                                    selected={
                                        { Id: "2d2f50a8-70b1-4848-8ed7-01f14e12fee4", Name: "Mark as ready" }
                                    }
                                    onClick={handleStatusChange}
                                    sx={{
                                        fontSize: 11
                                    }}
                                >
                                    Mark as ready
                                </MenuItem>
                            )
                    }
                </Menu>
            </Grid>
        </Grid>
    )
}

export default InvoiceTableItem