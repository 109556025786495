/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { Box, Grid } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { InvoiceContext } from '../../context/invoiceContext'

function DiaplyaCardsLayout({
    cardData
}) {
    const navigate = useNavigate();
    const { InitializeStatus, InitializeAppliedFilter, InitializeHighlightInvoice, InitializeIndex, InitializeNextInvoice, InitializeNextIndex } = useContext(InvoiceContext);

    const handleDisplayCard = (val) => {
        if (val.invoiceStatus === 'All Invoices') {
            InitializeStatus();
            InitializeAppliedFilter();
            InitializeHighlightInvoice();
            InitializeNextInvoice();
            InitializeNextIndex(0);
            InitializeIndex(0);
            navigate('/invoice');
        }
        else {
            InitializeStatus(val);
            InitializeAppliedFilter();
            InitializeHighlightInvoice();
            InitializeIndex(0);
            InitializeNextInvoice();
            InitializeNextIndex(0);
            navigate('/invoice', { state: { drillCardInfo: val } });
        }
    }

    return (
        <Grid
            container
            sx={{
                marginTop: '10px',
                alignItems: 'center',
                gap: "10px"
            }}
        >
            {
                cardData?.map((card, index) => (
                    <Grid item
                        xs={12} sm={1.9} md={1.9} lg={1.9}
                        key={index}
                        sx={{
                            height: '40px',
                            backgroundColor: (card?.colorCode === 0) ? ((card?.invoiceStatus === "Internal Dispute") ? '#ef5350' : '#ef9800') :
                                (card?.colorCode === 1) ? '#4caf50' : 'rgb(33 150 243)',
                            color: 'white',
                            cursor: 'pointer',
                            borderRadius: "5px",
                        }}
                        onClick={() => { handleDisplayCard(card) }}
                    >
                        <Box sx={{ justifyContent: 'center', display: 'flex', fontSize: 12 }}>
                            {card?.invoiceStatus}
                        </Box>
                        <Box sx={{ justifyContent: 'center', display: 'flex', fontSize: 16, fontWeight: "bold", whiteSpace: "nowrap" }}>
                            {card.totalRecords} | ${card.amount.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                        </Box>
                    </Grid>
                ))
            }
        </Grid>
    )
}

export default DiaplyaCardsLayout
