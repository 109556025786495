/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import FilterLayout from '../layout/filterlayout';
import DisplayCardLayout from '../layout/diaplyacardslayout';
import GraphLayout from '../layout/graphlayout';
import { getCardData, getChartData, getDisputeVolumeData, getAverageDispute } from '../../services/api';
import { DateFormatPayload } from '../../utils';
import LoaderComponent from '../../components/loader';
import CustomizedSnackbars from '../../components/snackbar';

function HomePage() {
    const [date] = useState(new Date());
    const date90DaysAgo = new Date();
    date90DaysAgo.setDate(date90DaysAgo.getDate() - process.env.REACT_APP_DAYS_FROM_DATE);
    const [fromDate] = useState(date90DaysAgo);
    const [cardData, setCardData] = useState([]);
    const [isCardLoading, setIsCardLoading] = useState(true);
    const [isChartLoading, setIsChartLoading] = useState(true);
    const [chartData, setChartData] = useState([]);
    const [disputeVolumeData, setDisputeVolumeData] = useState();
    const [isDisputeVolumeLoading, setIsDisputeVolumeLoading] = useState(true);
    const [avgDisputeData, setAvgDispute] = useState();
    const [isAvgDisputeLoading, setAvgDisputeLoading] = useState(true);
    const [kpiPayload, setKpiPayload] = useState({
        fromDate: DateFormatPayload(fromDate),
        toDate: DateFormatPayload(date),
        division: null,
        customerId: [],
        transactionType: 'INV',
        billingType: 'EB',
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
    const [message, setMessage] = useState("");
    const [Action, SetAction] = useState(false);
    const [snackType, setSnackType] = useState("success");

    const [isDataLoaded, setDataLoaded] = useState(false);

    const handleSnapClose = (value) => {
        SetAction(value);
    };

    useEffect(() => {
        setDataLoaded(false);
        setIsCardLoading(true);
        setIsChartLoading(true);
        setIsDisputeVolumeLoading(true);
        setAvgDisputeLoading(true);
        getCardData(kpiPayload)
            .then((res) => {
                setCardData(res);
            })
            .catch((_err) => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving card data. Refresh the page or contact System Administrator if the issue persists.");
            })
            .finally(() => setIsCardLoading(false));

        getChartData(kpiPayload)
            .then((res) => {
                setChartData(res);
            })
            .catch((_err) => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving chart data. Refresh the page or contact System Administrator if the issue persists.");
            })
            .finally(() => setIsChartLoading(false));

        getDisputeVolumeData(kpiPayload)
            .then((res) => {
                setDisputeVolumeData(res);
            })
            .catch((_err) => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving dispute volume data. Refresh the page or contact System Administrator if the issue persists.");
            })
            .finally(() => setIsDisputeVolumeLoading(false));
        getAverageDispute(kpiPayload).then((res) => {
            setAvgDispute(res);
        })
            .catch((_err) => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving average dispute data. Refresh the page or contact System Administrator if the issue persists.");
            })
            .finally(() => setAvgDisputeLoading(false));
    }
        , [kpiPayload]);

    useEffect(() => {
        if (!isCardLoading && !isChartLoading && !isDisputeVolumeLoading && !isAvgDisputeLoading) {
            setDataLoaded(true);
        }
        else {
            setDataLoaded(false);
        }
    }, [isCardLoading, isChartLoading, isDisputeVolumeLoading, isAvgDisputeLoading])

    const handleFilterApply = (payload, revert) => {
        if (revert) {
            const newPayload = {
                ...kpiPayload,
                customerId: [],
                division: null,
                fromDate: DateFormatPayload(fromDate),
                toDate: DateFormatPayload(date)
            };
            setKpiPayload(newPayload);
        } else {
            const newPayload = {
                ...kpiPayload,
                customerId: payload.CustomerId === '' ? kpiPayload.customerId : [payload.CustomerId],
                division: payload.DivisionId === '' ? kpiPayload.division : payload.DivisionId,
                fromDate: payload.FromDate === '' ? kpiPayload.fromDate : payload.FromDate,
                toDate: payload.ToDate === '' ? kpiPayload.toDate : payload.ToDate
            };
            setKpiPayload(newPayload);
        }
    };

    return (
        <>
            <FilterLayout
                applyfiltercallback={handleFilterApply}
                ebillingType={'EB'}
                enableFilters={!isDataLoaded}
            />
            {
                (isCardLoading || isChartLoading || isDisputeVolumeLoading || isAvgDisputeLoading) ?
                    <LoaderComponent
                        ht={'95%'}
                    />
                    :
                    (cardData && chartData && disputeVolumeData && avgDisputeData) &&
                    (
                        <>
                            <DisplayCardLayout cardData={cardData} />
                            <GraphLayout
                                chartData={chartData}
                                disputeVolumeData={disputeVolumeData}
                                averageDispute={avgDisputeData}
                            />
                        </>
                    )
            }
            {
                Action &&
                <CustomizedSnackbars
                    message={message}
                    snackstatus={Action}
                    onCloseCallback={handleSnapClose}
                    snacktype={snackType}
                />
            }
        </>
    );
}

export default HomePage;
