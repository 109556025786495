/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */

import React, { createContext, useEffect, useState } from 'react'
import { useMsal } from "@azure/msal-react";

const InvoiceContext = createContext();


const InvoiceProvider = ({ children }) => {
    const [signedInUser, setSignedInUser] = useState();
    const [selectedStatus, setSelectedStatus] = useState();
    const [appliedFilter, setAppliedFilter] = useState();
    const [appliedSearch, setAppliedSearch] = useState();
    const [highlightInvoice, setHighlightInvoice] = useState();
    const [nextInvoice, setNextInvoice] = useState();
    const [selectedIndex, setIndex] = useState();
    const [nextIndex, setNextIndex] = useState();
    const { instance } = useMsal();

    useEffect(() => {
        if (instance) {
            const activeAccount = instance?.getActiveAccount();
            if (activeAccount) {
                setSignedInUser(activeAccount);
            }
        }
    }, []);

    const InitializeSignedInUser = (value) => {
        setSignedInUser(value);
    }
    const InitializeStatus = (value) => {
        setSelectedStatus(value);
    }
    const InitializeAppliedFilter = (value) => {
        setAppliedFilter(value);
    }
    const InitializeAppliedSearch = (value) => {
        setAppliedSearch(value);
    }
    const InitializeHighlightInvoice = (value) => {
        setHighlightInvoice(value);
    }
    const InitializeNextInvoice = (value) => {
        setNextInvoice(value);
    }
    const InitializeIndex = (value) => {
        setIndex(value);
    }
    const InitializeNextIndex = (value) => {
        setNextIndex(value);
    }

    return (
        <InvoiceContext.Provider
            value={{
                selectedStatus,
                InitializeStatus,
                signedInUser,
                InitializeSignedInUser,
                appliedFilter,
                InitializeAppliedFilter,
                appliedSearch,
                InitializeAppliedSearch,
                highlightInvoice,
                InitializeHighlightInvoice,
                selectedIndex,
                InitializeIndex,
                nextInvoice,
                InitializeNextInvoice,
                nextIndex,
                InitializeNextIndex
            }}
        >
            {children}
        </InvoiceContext.Provider>
    );
};

export { InvoiceContext, InvoiceProvider };





