/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip, Grid } from '@mui/material';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useNavigate } from 'react-router';
import { InvoiceContext } from '../../context/invoiceContext';
import StallionLogo from '../../assets/logo-dark-2023.svg';
import routes from '../../constants/routes.json';

function LoginPage() {
    const { InitializeSignedInUser } = useContext(InvoiceContext);
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [signIn, setSignIn] = useState(false);

    useEffect(() => {
        const activeAccount = instance?.getActiveAccount();
        if (activeAccount) {
            if (activeAccount?.idTokenClaims?.roles?.includes("TreasuryUsers")) {
                navigate(routes?.HOME);
            } else if (activeAccount?.idTokenClaims?.roles?.includes("RegionalUsers")) {
                navigate(routes?.INVOICE);
            }
        }
    }, [signIn]);

    const handleLoginRedirect = () => {
        instance.loginPopup({
            ...loginRequest,
            redirectUri: '/login'
        })
            .then((res) => {
                if (res) {
                    InitializeSignedInUser(res);
                    const accessToken = res.accessToken;
                    const tokenExpiry = res.expiresOn;
                    localStorage.setItem('accessToken', accessToken);
                    localStorage.setItem('tokenExpiry', tokenExpiry);
                    setSignIn(prv => !prv);
                }
            })
            .catch((_error) => {
                setSignIn(prv => !prv);
            });
    };

    return (
        <Grid
            container
            height='100vh'
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Grid
                item
                component="img"
                xs={6} sm={6} md={6} lg={6}
                sx={{
                }}
                alt="Stallion Infrastructure"
                src={StallionLogo}
            />
            <Tooltip title="Sign in with Stallion">
                <Button
                    variant="outlined"
                    onClick={() => { handleLoginRedirect() }}
                    sx={{
                        padding: '1px',
                        width: '5%',
                        height: '5%',
                        textTransform: 'none',
                        fontSize: '14px',
                        borderRadius: '3px',
                        background: '#edf7fc',
                        fontFamily: 'DM Sans',
                        ":hover": {
                            background: '#D0DFFF'
                        }
                    }}>
                    Sign In
                </Button>
            </Tooltip>
        </Grid>
    )
}

export default LoginPage
