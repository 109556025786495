/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import { Paper, Box, Typography } from "@mui/material";
import "./style.css";
import routes from "../../constants/routes.json";
import { useNavigate, useLocation } from "react-router-dom";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { useMsal } from "@azure/msal-react";
import { InvoiceContext } from "../../context/invoiceContext";

const DetachedSidebar = () => {
    const { signedInUser, InitializeStatus } = useContext(InvoiceContext);
    const [isHomeHovered, setIsHomeHovered] = useState(false);
    const [isInvoiceHovered, setIsInvoiceHovered] = useState(false);
    const [isSettingsHovered, setIsSettingsHovered] = useState(false);
    const [isLogoutHovered, setIsLogoutHovered] = useState(false);

    const { instance } = useMsal();
    const navigate = useNavigate();
    const location = useLocation();

    let activeAccount;
    if (instance) {
        activeAccount = instance.getActiveAccount();
    }

    const onClickHandler = (path) => {
        navigate(path);
    };

    const handleLogoutPopup = () => {
        instance.logoutRedirect({
            account: activeAccount,
            mainWindowRedirectUri: "/login",
        });
    };

    const isPathActive = (path) => location.pathname === path;

    return (
        <Paper
            square
            className="sidebar"
            data-testid="navbar"
            sx={{
                background: "white",
                boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
                zIndex: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100vh',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    marginTop: 'auto',
                    marginBottom: 'auto',
                }}>
                {
                    (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '12%',
                                display: 'flex',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={() => setIsHomeHovered(true)}
                            onMouseLeave={() => setIsHomeHovered(false)}
                            onClick={() => onClickHandler(routes?.HOME)}
                        >
                            <Box
                                sx={{
                                    width: '5%',
                                    display: 'flex',
                                    backgroundColor: isHomeHovered || isPathActive(routes.HOME) ? '#2196f3' : 'inherit',
                                }}
                            />
                            <Box
                                sx={{
                                    width: '95%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: isHomeHovered || isPathActive(routes.HOME) ? '#f5f5f5' : 'inherit',
                                }}
                            >
                                {
                                    isHomeHovered || isPathActive(routes.HOME) ?
                                        <HomeIcon
                                            sx={{
                                                color: '#2196f3',
                                            }}
                                        /> :
                                        <HomeOutlinedIcon
                                            sx={{
                                                color: '#757575'
                                            }}
                                        />
                                }
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        color: isHomeHovered || isPathActive(routes.HOME) ? '#2196f3' : '#757575'
                                    }}
                                >
                                    Home
                                </Typography>
                            </Box>
                        </Box>
                    )
                }
                <Box
                    sx={{
                        width: '100%',
                        height: '12%',
                        display: 'flex',
                        cursor: 'pointer',
                    }}
                    onMouseEnter={() => setIsInvoiceHovered(true)}
                    onMouseLeave={() => setIsInvoiceHovered(false)}
                    onClick={() => onClickHandler(routes?.INVOICE)}
                >
                    <Box
                        sx={{
                            width: '5%',
                            display: 'flex',
                            backgroundColor: isInvoiceHovered || isPathActive(routes.INVOICE) ? '#2196f3' : 'inherit',
                        }}
                    />
                    <Box
                        sx={{
                            width: '95%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: isInvoiceHovered || isPathActive(routes.INVOICE) ? '#f5f5f5' : 'inherit',
                        }}
                    >
                        <FormatListBulletedIcon
                            sx={{
                                color: isInvoiceHovered || isPathActive(routes.INVOICE) ? '#2196f3' : '#757575',
                            }}
                        />
                        <Typography
                            sx={{
                                fontSize: 10,
                                color: isInvoiceHovered || isPathActive(routes.INVOICE) ? '#2196f3' : '#757575'
                            }}
                        >
                            Invoices
                        </Typography>
                    </Box>
                </Box>
                {
                    (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) && (
                        <Box
                            sx={{
                                width: '100%',
                                height: '12%',
                                display: 'flex',
                                cursor: 'pointer',
                            }}
                            onMouseEnter={() => setIsSettingsHovered(true)}
                            onMouseLeave={() => setIsSettingsHovered(false)}
                            onClick={() => onClickHandler(routes?.SETTINGS)}
                        >
                            <Box
                                sx={{
                                    width: '5%',
                                    display: 'flex',
                                    backgroundColor: isSettingsHovered || isPathActive(routes.SETTINGS) ? '#2196f3' : 'inherit',
                                }}
                            />
                            <Box
                                sx={{
                                    width: '95%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: isSettingsHovered || isPathActive(routes.SETTINGS) ? '#f5f5f5' : 'inherit',
                                }}
                            >
                                {
                                    isSettingsHovered || isPathActive(routes.SETTINGS) ?
                                        <SettingsIcon
                                            sx={{
                                                color: '#2196f3',
                                            }}
                                        /> :
                                        <SettingsOutlinedIcon
                                            sx={{
                                                color: '#757575'
                                            }}
                                        />
                                }
                                <Typography
                                    sx={{
                                        fontSize: 10,
                                        color: isSettingsHovered || isPathActive(routes.SETTINGS) ? '#2196f3' : '#757575'
                                    }}
                                >
                                    Settings
                                </Typography>
                            </Box>
                        </Box>
                    )
                }
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '10%',
                    display: 'flex',
                    cursor: 'pointer'
                }}
                onMouseEnter={() => setIsLogoutHovered(true)}
                onMouseLeave={() => setIsLogoutHovered(false)}
                onClick={handleLogoutPopup}
            >
                <Box
                    sx={{
                        width: '5%',
                        display: 'flex',
                        backgroundColor: isLogoutHovered ? '#f44336' : 'inherit',
                    }}
                />
                <Box
                    sx={{
                        width: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: isLogoutHovered ? '#ffcdd2' : 'inherit',
                    }}
                >
                    <PowerSettingsNewIcon
                        sx={{
                            color: isLogoutHovered ? '#f44336' : '#757575'
                        }}
                    />
                    <Typography
                        sx={{
                            fontSize: 10,
                            color: isLogoutHovered ? '#f44336' : '#757575'
                        }}
                    >
                        Log out
                    </Typography>
                </Box>
            </Box>
        </Paper>
    );
};

export default DetachedSidebar;
