/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import * as XLSX from 'xlsx';
import FilterLayout from '../layout/filterlayout'
import { Virtuoso } from 'react-virtuoso';
import { Grid, Chip, Typography, Box, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import InvoiceTableItem from '../../components/invoicetableitem';
import InvoiceTableHeader from '../../components/invoicetableheader';
import CustomizedSnackbars from '../../components/snackbar';
import ResponsiveDialog from '../../components/dialog';
import StatusDialog from '../../components/statusDialog';
import { DateFormatPayload } from '../../utils';
import { InvoiceContext } from '../../context/invoiceContext';
import LoaderComponent from '../../components/loader';
import LoadingRowSkeleton from '../../components/row-loader';
import NoResults from '../../components/noresults';
// Api Import 
import { getAllInvoices, getAllDisputeCodes, getInvoicesCount, updateInvoiceLabel, updateStatus, getSelectedIndex, invoicesExport } from '../../services/api';
// Mapping Import 
import Divisonmapping from '../../constants/divisionMapping.json';
const FooterResponse = [{
    label: 'Total Records',
}, {
    label: 'Total Invoice Amount',
}]

function InvoicePage() {

    const [invoices, SetInvoices] = useState(() => []);
    const [priority, setPriority] = useState([
        {
            label: 0,
            selected: true
        },
        {
            label: 1,
            selected: false
        },
        {
            label: 2,
            selected: false
        }
    ]);
    const [billingType, setBillingType] = useState([
        {
            label: "EB",
            selected: true
        },
        {
            label: "EA",
            selected: false
        }
    ]);
    const [excelHeaders] = useState([
        "Invoice Number", "Invoice Status", "Priority Level", "Invoice Date", "Invoice Amount($)", "Invoice Age", "Customer Id", "Customer Name", "Division", "Billing Type",
        "Dispute Code", "Modified On", "Labels", "SharePoint Item Link"
    ])
    const [Action, SetAction] = useState(false);
    const [isDialog, SetIsDialog] = useState(false);
    const [statusPayload, setStatusPayload] = useState();
    const [dialogvalue, setDialogvalue] = useState("");
    const [confirmdialog, setConfirmDialog] = useState(false);
    const [selectedinvoice, setSelectedInvoice] = useState();
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [disputeInfo, setDisputeInfo] = useState([]);
    const [isView, setIsView] = useState(false);
    const [invDetails, setInvCount] = useState({
        totalRecords: 0,
        totalAmount: 0
    })
    const [message, setMessage] = useState("");
    const [isInvChanged, setInvchanged] = useState(false);
    const [dialogTitle, setDialogTitle] = useState("");
    const [activeLabel, setActiveLabel] = useState("");
    //
    const [date] = useState(new Date()); const date90DaysAgo = new Date();
    date90DaysAgo.setDate(date90DaysAgo.getDate() - process.env.REACT_APP_DAYS_FROM_DATE);
    const [fromDate] = useState(date90DaysAgo);
    //
    const [greyOut, setGreyOut] = useState(false);
    const { selectedStatus, signedInUser, InitializeStatus, appliedFilter, InitializeAppliedFilter, appliedSearch,
        highlightInvoice,
        InitializeHighlightInvoice,
        selectedIndex,
        InitializeIndex,
        InitializeNextInvoice,
        nextInvoice,
        nextIndex,
        InitializeNextIndex
    } = useContext(InvoiceContext);
    const [invRequestPayload, setInvRequestPayload] = useState(
        {
            fromDate: DateFormatPayload(fromDate),
            toDate: DateFormatPayload(date),
            division: "",
            customerId: [],
            statusId: null,
            disputeCodeId: null,
            transactionType: 'INV',
            billingType: 'EB',
            labelTag: activeLabel,
            priority: 0,
            searchKeyword: "",
            sortColumn: "",
            sortAscending: true,
            pageNumber: 1,
            recordsPerPage: 20,
            selectedInvoice: null,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    );
    const [isStatusChange, setStatusChange] = useState(false);
    const [ebillType, setEbillType] = useState("EB");
    const [snackType, setSnackType] = useState("success");
    const [refresh, setRefresh] = useState(false);
    const [isInfoHovered, setHomeHovered] = useState(false);
    //
    const [isInvoiceLoaded, setInvoiceLoaded] = useState(false);
    // ----------------------------------------------------------------------
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
    const [topIndex, setTopIndex] = useState(0);
    const handleSelectInvoice = (invoiceId, index) => {
        setSelectedInvoiceId(invoiceId);
        InitializeHighlightInvoice(invoiceId);
        InitializeIndex(index);
        setTopIndex(index);
        if (invoices[index + 1]) {
            InitializeNextInvoice(invoices[index + 1].invoiceNumber);
            InitializeNextIndex(index + 1);
        }
        else if (invoices[index - 1]) {
            InitializeNextInvoice(invoices[index - 1].invoiceNumber);
            InitializeNextIndex(index - 1);
        }
        else {
            InitializeNextInvoice(null);
            InitializeNextIndex(0);
        }
    };
    // ----------------------------------------------------------------------

    const fetchBatchApi = () => {
        setLoader(true);
        let bodyParams = {
            ...invRequestPayload,
            pageNumber: page
        };
        if (appliedFilter) {
            bodyParams.fromDate = appliedFilter.fromDate;
            bodyParams.toDate = appliedFilter.toDate;
            bodyParams.division = appliedFilter.division;
            bodyParams.customerId = appliedFilter.customerId;
            bodyParams.statusId = appliedFilter.statusId;
            bodyParams.billingType = appliedFilter.billingType;
            bodyParams.priority = appliedFilter.priority;
            if ((appliedFilter.statusId !== 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522')
                && (appliedFilter.statusId !== null)
            ) {
                bodyParams.disputeCodeId = null;
            }
            else
                bodyParams.disputeCodeId = appliedFilter.disputeCodeId;
        }
        if (appliedSearch) {
            bodyParams.searchKeyword = appliedSearch
        }
        if (selectedStatus) {
            bodyParams.statusId = selectedStatus.currentStatusId;
            if ((selectedStatus.invoiceStatus !== 'Internal Dispute')
                && (selectedStatus.invoiceStatus !== 'All')
            ) {
                bodyParams.disputeCodeId = null;
            }
        }
        if (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) {
            bodyParams.statusId = 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522';
            if (signedInUser) {
                if (findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== "" &&
                    findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== null) {
                    bodyParams.division = findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups);
                }
            }
        }
        if (highlightInvoice) {
            bodyParams.selectedInvoice = highlightInvoice;
            getSelectedIndex(bodyParams).then((response) => {
                if (response !== null) {
                    InitializeIndex(response);
                    setTopIndex(response);
                    bodyParams.recordsPerPage = (Math.floor(response / 20) + 2) * 20;
                    getAllInvoices(bodyParams).then((response) => {
                        if (response) {
                            if (page > 1) {
                                SetInvoices(prevState => ([...prevState, ...response]));
                            } else {
                                SetInvoices(response);
                            }
                        }
                    }).catch(_err => {
                        setSnackType("error");
                        SetAction(true);
                        setMessage("Error retrieving invoice data. Refresh the page or contact System Administrator if the issue persists.");
                    }).finally(() => {
                        setLoader(false);
                    });
                }
            }).catch(_err => {
            });
        }
        else {
            getAllInvoices(bodyParams).then((response) => {
                if (response) {
                    if (page > 1) {
                        SetInvoices(prevState => ([...prevState, ...response]));
                    } else {
                        SetInvoices(response);
                    }
                }
            }).catch(_err => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving invoice data. Refresh the page or contact System Administrator if the issue persists.");
            }).finally(() => {
                setLoader(false);
            });
        }
    };

    const fetchNextBatchApi = () => {
        let bodyParams = {
            ...invRequestPayload,
            pageNumber: page
        };
        if (appliedFilter) {
            bodyParams.fromDate = appliedFilter.fromDate;
            bodyParams.toDate = appliedFilter.toDate;
            bodyParams.division = appliedFilter.division;
            bodyParams.customerId = appliedFilter.customerId;
            bodyParams.statusId = appliedFilter.statusId;
            bodyParams.billingType = appliedFilter.billingType;
            bodyParams.priority = appliedFilter.priority;
            if ((appliedFilter.statusId !== 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522')
                && (appliedFilter.statusId !== null)
            ) {
                bodyParams.disputeCodeId = null;
            }
            else
                bodyParams.disputeCodeId = appliedFilter.disputeCodeId;
        }
        if (appliedSearch) {
            bodyParams.searchKeyword = appliedSearch
        }
        if (selectedStatus) {
            bodyParams.statusId = selectedStatus.currentStatusId;
            if ((selectedStatus.invoiceStatus !== 'Internal Dispute')
                && (selectedStatus.invoiceStatus !== 'All')
            ) {
                bodyParams.disputeCodeId = null;
            }
        }
        if (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) {
            bodyParams.statusId = 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522';
            if (signedInUser) {
                if (findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== "" &&
                    findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== null) {
                    bodyParams.division = findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups);
                }
            }
        }
        getAllInvoices(bodyParams).then((response) => {
            if (response) {
                if (page > 1) {
                    SetInvoices(prevState => ([...prevState, ...response]));
                } else {
                    SetInvoices(response);
                }
            }
        }).catch(_err => {
            setSnackType("error");
            SetAction(true);
            setMessage("Error retrieving invoice data. Refresh the page or contact System Administrator if the issue persists.");
        })
    }

    const fetchTotalCount = () => {
        let bodyParams = {
            ...invRequestPayload,
        };
        if (appliedFilter) {
            bodyParams.fromDate = appliedFilter.fromDate;
            bodyParams.toDate = appliedFilter.toDate;
            bodyParams.division = appliedFilter.division;
            bodyParams.customerId = appliedFilter.customerId;
            bodyParams.statusId = appliedFilter.statusId;
            bodyParams.billingType = appliedFilter.billingType;
            bodyParams.priority = appliedFilter.priority;
            if ((appliedFilter.statusId !== 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522')
                && (appliedFilter.statusId !== null)
            ) {
                bodyParams.disputeCodeId = null;
            }
            else
                bodyParams.disputeCodeId = appliedFilter.disputeCodeId;
        }
        if (appliedSearch) {
            bodyParams.searchKeyword = appliedSearch
        }
        if (selectedStatus) {
            bodyParams.statusId = selectedStatus.currentStatusId;
            if ((selectedStatus.invoiceStatus !== 'Internal Dispute')
                && (selectedStatus.invoiceStatus !== 'All')
            ) {
                bodyParams.disputeCodeId = null;
            }
        }
        if (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) {
            bodyParams.statusId = 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522';
            if (signedInUser) {
                if (findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== "" &&
                    findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== null) {
                    bodyParams.division = findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups);
                }
            }
        }
        getInvoicesCount(bodyParams).then((response) => {
            if (response) {
                setInvCount(response)
            }
        }).catch(_err => {
            setSnackType("error");
            SetAction(true);
            setMessage("Error retrieving total invoice metrics. Refresh the page or contact System Administrator if the issue persists.");
        })
    }

    const fetchExportData = (val) => {
        let bodyParams = {
            ...invRequestPayload,
            pageNumber: page
        };
        if (val === 0) {
            if (appliedFilter) {
                bodyParams.fromDate = appliedFilter.fromDate;
                bodyParams.toDate = appliedFilter.toDate;
                bodyParams.division = appliedFilter.division;
                bodyParams.customerId = appliedFilter.customerId;
                bodyParams.statusId = appliedFilter.statusId;
                bodyParams.billingType = appliedFilter.billingType;
                bodyParams.priority = appliedFilter.priority;
                if ((appliedFilter.statusId !== 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522')
                    && (appliedFilter.statusId !== null)
                ) {
                    bodyParams.disputeCodeId = null;
                }
                else
                    bodyParams.disputeCodeId = appliedFilter.disputeCodeId;
            }
            if (appliedSearch) {
                bodyParams.searchKeyword = appliedSearch
            }
            if (selectedStatus) {
                bodyParams.statusId = selectedStatus.currentStatusId;
                if ((selectedStatus.invoiceStatus !== 'Internal Dispute')
                    && (selectedStatus.invoiceStatus !== 'All')
                ) {
                    bodyParams.disputeCodeId = null;
                }
            }
            if (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) {
                bodyParams.statusId = 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522';
                if (signedInUser) {
                    if (findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== "" &&
                        findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== null) {
                        bodyParams.division = findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups);
                    }
                }
            }
        }
        else {
            bodyParams = {
                fromDate: '2016-01-01',
                toDate: DateFormatPayload(date),
                transactionType: 'INV',
                billingType: invRequestPayload?.billingType,
                statusId: null,
                division: "",
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            if (!signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) {
                bodyParams.statusId = 'd7a9cbca-c9e3-4e18-b7aa-40087b8a3522';
                if (signedInUser) {
                    if (findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== "" &&
                        findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups) !== null) {
                        bodyParams.division = findMappingDivisionName(Divisonmapping, signedInUser?.idTokenClaims?.groups);
                    }
                }
            }
        }
        invoicesExport(bodyParams).then((response) => {
            if (response) {
                const date = new Date().toLocaleDateString('en-US').replace(/\//g, '-');
                const fileName = "Invoices_" + date + ".xlsx";
                exportToExcel(response, fileName);
                setSnackType("success");
                SetAction(true);
                setMessage("Export successful.");
            }
        }).catch(_err => {
            setSnackType("error");
            SetAction(true);
            setMessage("Error exporting data. Please try again later or contact System Administrator if the issue persists.");
        });
    }

    const findMappingDivisionName = (Divisonmapping, groups) => {
        for (const group of groups) {
            const found = Divisonmapping.find(item => item.groupId === group);
            if (found) {
                return found.mappingDivisonName;
            }
        }
        return null;
    };

    useEffect(() => {
        if (selectedStatus) {
            setGreyOut(true);
        }
        else {
            setGreyOut(false);
        }
        if (page > 1)
            fetchNextBatchApi();
    }, [page]);

    useEffect(() => {
        if (signedInUser) {
            setLoader(true);
            setPage(1);
            fetchBatchApi();
            fetchTotalCount();
        }
    }, [invRequestPayload, isInvChanged, signedInUser, refresh]);

    useEffect(() => {
        if (!loader) {
            setInvoiceLoaded(true);
        }
        else {
            setInvoiceLoaded(false);
        }
    }, [invoices, loader]);

    useEffect(() => {
        if (appliedFilter) {
            setInvRequestPayload(appliedFilter);
            const temp = billingType.map((ele) => {
                if (ele.label === appliedFilter.billingType) {
                    ele.selected = true;
                } else {
                    ele.selected = false;
                }
                return ele;
            });
            setBillingType(temp);
            setEbillType(appliedFilter.billingType);
            const priorityTemp = priority.map((ele) => {
                if (ele.label === appliedFilter.priority) {
                    ele.selected = true;
                } else {
                    ele.selected = false;
                }
                return ele;
            });
            setPriority(priorityTemp);
        }
        else {
            setBillingType([
                {
                    label: "EB",
                    selected: true
                },
                {
                    label: "EA",
                    selected: false
                }
            ]);
            setPriority([
                {
                    label: 0,
                    selected: true
                },
                {
                    label: 1,
                    selected: false
                },
                {
                    label: 2,
                    selected: false
                }
            ])
        }
    }, [appliedFilter]);

    useEffect(() => {
        if (highlightInvoice) {
            setSelectedInvoiceId(highlightInvoice);
        }
        else {
            setSelectedInvoiceId();
        }
    }, [highlightInvoice]);

    const onLoadMore = () => {
        if (invoices?.length < invDetails.totalRecords) {
            if (selectedIndex && page === 1) {
                setPage(Math.floor(selectedIndex / 20) + 3);
            }
            else {
                setPage(prevState => prevState + 1);
            }
        }
    };

    const handleFilterApply = (payload, revert) => {
        // api call for filter
        if (revert) {
            setPage(1);
            const newPayload = {
                ...invRequestPayload,
                customerId: [],
                division: null,
                disputeCodeId: null,
                statusId: null,
                fromDate: DateFormatPayload(fromDate),
                toDate: DateFormatPayload(date),
                billingType: 'EB',
                priority: 0,
                pageNumber: 1
            };
            setInvRequestPayload(newPayload);
            setEbillType('EB');
            InitializeAppliedFilter();
            InitializeStatus();
        }
        else {
            setPage(1);
            console.log(payload);
            if (selectedStatus) {
                if (payload.statusId !== selectedStatus?.currentStatusId) {
                    InitializeStatus();
                }
            }
            // Define the base newPayload
            let newPayload = {
                ...invRequestPayload,
                customerId: payload.CustomerId === '' ? appliedFilter ? appliedFilter.customerId : invRequestPayload.customerId : payload.CustomerId,
                division: payload.DivisionId === '' ? appliedFilter ? appliedFilter.division : invRequestPayload.division : payload.DivisionId,
                disputeCodeId: payload.disputeCodeId === '' ? appliedFilter ? appliedFilter.disputeCodeId : null : payload.disputeCodeId,
                fromDate: payload.FromDate === '' ? appliedFilter ? appliedFilter.fromDate : invRequestPayload.fromDate : payload.FromDate,
                statusId: payload.statusId === '' ? appliedFilter ? appliedFilter.statusId : invRequestPayload.statusId : payload.statusId,
                toDate: payload.ToDate === '' ? appliedFilter ? appliedFilter.toDate : invRequestPayload.toDate : payload.ToDate,
                pageNumber: 1
            };
            console.log(newPayload);
            setInvRequestPayload(newPayload);
            InitializeAppliedFilter(newPayload);
        }
    }

    const handlestatuschange = (value, record) => {
        setStatusPayload(value);
        setStatusChange(true);
        if (value.Name === "Mark as ready") {
            setDialogvalue(`Do you want to mark the Invoice Number ${record.invoiceNumber} as ready?`);
        }
        else {
            setDialogvalue(`Do you want to change the status of Invoice Number ${record.invoiceNumber} to "${value.Name}"?`);
        }
        setSelectedInvoice(record);
        getAllDisputeCodes().then((response) => {
            if (response) setDisputeInfo(response)
        }).catch((_err) => {
            setSnackType("error");
            SetAction(true);
            setMessage("Error retrieving dispute codes. Refresh the page or contact System Administrator if the issue persists.");
        })
    }

    const handlefinalStatusChange = (value, comment, labels, selectedDispute) => {
        if (value) {
            setStatusChange(false);
            // api call
            const payLabels = labels.map((lab) => {
                return lab.name;
            });
            const bodyParams = {
                invoiceNumber: selectedinvoice?.invoiceNumber,
                disputeCodeId: selectedDispute?.Id,
                statusId: statusPayload?.Id,
                comments: comment,
                labels: payLabels
            };
            if (bodyParams) {
                updateStatus(bodyParams).then((response) => {
                    if (response.isSuccessful) {
                        setInvchanged(prv => !prv);
                        setSnackType('success');
                        SetAction(true);
                        setMessage("Status Updated Successfully.");
                        // Highlight nexr invoice in the queue
                        if (invoices[selectedIndex + 1]) {
                            InitializeHighlightInvoice(invoices[selectedIndex + 1].invoiceNumber);
                            setTopIndex(prv => prv + 1);
                            InitializeIndex(prv => prv + 1);
                        }
                        else if (invoices[selectedIndex - 1]) {
                            InitializeHighlightInvoice(invoices[selectedIndex - 1].invoiceNumber);
                            setTopIndex(prv => prv - 1);
                            InitializeIndex(prv => prv - 1);
                        }
                    }
                }).catch((err) => {
                    SetAction(true);
                    setSnackType('error');
                    setMessage(err.message);
                });
            }
        }
        else {
            setStatusChange(false)
        }
    }

    const handleEditLable = (value, record) => {
        SetIsDialog(value);
        setDialogTitle("Add/Edit Labels");
        setConfirmDialog(false);
        setDialogvalue(`Add Label`);
        setSelectedInvoice(record);
        setIsView(false);
    }

    const handlefinalLabelChange = (value, newLabels, Inv) => {
        if (value) {
            SetIsDialog(false)
            const labelpayload = newLabels.map((ind) => {
                return ind.name
            });
            const payload = {
                invoiceNumber: Inv.invoiceNumber,
                labels: labelpayload
            }
            if (payload) {
                updateInvoiceLabel(payload).then((response) => {
                    if (response.isSuccessful) {
                        SetAction(true);
                        setMessage("Label(s) updated successfully.");
                        setInvchanged(prv => !prv);
                    }
                }).catch((err) => {
                    setSnackType("error");
                    SetAction(true);
                    setMessage(err.message);
                })
            }
        }
        else {
            SetIsDialog(false)
        }

    }

    const handleSearchApply = (searchword) => {
        setPage(1);
        const newPayload = {
            ...invRequestPayload,
            searchKeyword: searchword,
            pageNumber: 1
        };
        setInvRequestPayload(newPayload)
    }
    // sort
    const handleSortApply = (sortHeader, sortVariable) => {
        InitializeHighlightInvoice();
        InitializeNextInvoice();
        setSelectedInvoiceId();
        setTopIndex(0);
        InitializeIndex(0);
        InitializeNextIndex(0);
        const newPayload = {
            ...invRequestPayload,
            sortColumn: sortHeader,
            sortAscending: sortVariable,
            selectedInvoice: null
        }
        setInvRequestPayload(newPayload);
    }

    const handleSnapClose = (value) => {
        SetAction(value);
    };

    const handleLabelFilter = (value) => {
        setActiveLabel(value);
        setPage(1);
        const newPayload = {
            ...invRequestPayload,
            labelTag: value,
            pageNumber: 1
        };
        setInvRequestPayload(newPayload)
    }

    const handleBillingTypeFilter = (value, label) => {
        const temp = billingType.map((ele) => {
            if (ele.label === label) {
                ele.selected = true;
            } else {
                ele.selected = false;
            }
            return ele;
        });
        setBillingType(temp);
        setPage(1);
        const newPayload = {
            ...invRequestPayload,
            billingType: value,
            pageNumber: 1
        };
        setEbillType(value);
        setInvRequestPayload(newPayload);
        InitializeAppliedFilter(newPayload);
    }

    const handlePriorityFilter = (value, label) => {
        const temp = priority.map((ele) => {
            if (ele.label === label) {
                ele.selected = true;
            } else {
                ele.selected = false;
            }
            return ele;
        });
        setPriority(temp);
        setPage(1);
        const newPayload = {
            ...invRequestPayload,
            priority: value,
            pageNumber: 1
        };
        setInvRequestPayload(newPayload);
        InitializeAppliedFilter(newPayload);
    }

    const handleRefresh = () => {
        setInvoiceLoaded(false);
        setRefresh(prv => !prv);
    }

    const handleXLSExport = (val) => {
        setSnackType("info");
        SetAction(true);
        setMessage("Exporting List.");
        fetchExportData(val);
    }

    const exportToExcel = (data, fileName = 'table_data.xlsx') => {
        const formattedData = [
            excelHeaders,  // Assuming this contains the header row
            ...data.map((item) => [
                item.invoiceNumber,
                item.invoiceStatus,
                item.priorityLevel,
                item.invoiceDate,
                item.invoiceAmount,
                item.invoiceAge,
                item.customerId,
                item.customerName,
                item.division,
                item.billingType,
                item.disputeCode,
                item.modifiedOn,
                item.labels,
                item.invoiceSPLink
            ])
        ];
        const worksheet = XLSX.utils.aoa_to_sheet(formattedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Invoices");
        XLSX.writeFile(workbook, fileName);
    };

    return (
        <>
            <FilterLayout
                isInvoiceScreen={true}
                applyfiltercallback={handleFilterApply}
                greyOut={greyOut}
                searchInvoiceCallback={handleSearchApply}
                activeLabel={activeLabel}
                labelAppliedCallback={handleLabelFilter}
                ebillingType={ebillType}
                refreshCallback={handleRefresh}
                enableFilters={!isInvoiceLoaded}
            />
            {/* Table Container */}
            <Grid
                container
                sx={{
                    width: '100%',
                    height: '98%'
                }}
            >
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        width: '100%',
                        height: '94%'
                    }}
                >
                    <InvoiceTableHeader
                        sortApplyCallback={handleSortApply}
                        exportCallback={handleXLSExport}
                        signedInUser={signedInUser}
                        isCompleted={appliedFilter && appliedFilter.statusId ? appliedFilter.statusId === '0279c448-342a-4d29-a5f6-f52b9d2e66dc' : selectedStatus ? selectedStatus === '0279c448-342a-4d29-a5f6-f52b9d2e66dc' : false}
                    />
                    {loader ? (
                        <LoaderComponent
                            ht={'92%'}
                        />
                    ) :
                        (invoices.length > 0) ?
                            <Grid
                                container
                                sx={{
                                    width: '100%',
                                    height: '92%'
                                }}
                            >
                                <Virtuoso
                                    style={{ width: '100%' }}
                                    data={invoices}
                                    endReached={onLoadMore}
                                    increaseViewportBy={200}
                                    initialTopMostItemIndex={topIndex}
                                    itemContent={(index, item) => (
                                        <InvoiceTableItem
                                            key={index}
                                            invoicedata={item}
                                            statuspayloadcallback={handlestatuschange}
                                            labeleditcallback={handleEditLable}
                                            labelfiltercallback={handleLabelFilter}
                                            ebillType={ebillType}
                                            isSelected={item.invoiceNumber === selectedInvoiceId}
                                            onSelect={() => handleSelectInvoice(item.invoiceNumber, index)}
                                        />
                                    )}
                                    components={invoices?.length < invDetails.totalRecords && <LoadingRowSkeleton />}
                                />
                            </Grid>
                            :
                            <NoResults />
                    }
                </Grid>
                {/* Footer Container */}
                <Grid
                    container
                    sx={{
                        alignItems: 'center',
                        marginTop: '5px',
                        height: '6%'
                    }}
                >
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            width: '63%',
                            height: '100%',
                            alignItems: 'center'
                        }}
                    >
                        {
                            FooterResponse.map((foot, index) => (
                                <Grid item
                                    xs={12} sm={4} md={4} lg={4}
                                    key={index}
                                    sx={{
                                        color: 'white',
                                        borderRadius: "5px",
                                        marginRight: '5px',
                                        display: 'flex',
                                        height: '90%',
                                        fontSize: 12,
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    {foot.label} : {(index === 0) ?
                                        invDetails.totalRecords
                                        :
                                        '$' + invDetails.totalAmount.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        }
                                        )}
                                </Grid>
                            ))
                        }
                    </Grid>
                    <Grid
                        item
                        sx={{
                            display: 'flex',
                            width: '37%',
                            height: '100%',
                        }}
                    >

                        <Grid item
                            xs={12} sm={12} md={12} lg={12}
                            sx={{
                                display: 'flex',
                                height: '100%',
                                alignItems: 'center',
                                justifyContent: 'space-around'
                            }}
                        >
                            {
                                (signedInUser?.idTokenClaims?.roles?.includes('TreasuryUsers')) ?
                                    <>
                                        <Typography sx={{
                                            fontSize: "12px",
                                            fontWeight: "bold"
                                        }}>
                                            Priority :
                                        </Typography>
                                        <Chip
                                            label="All"
                                            onClick={() => { handlePriorityFilter(0, 0) }}
                                            sx={{
                                                backgroundColor: priority[0].selected && "#4caf50",
                                                color: priority[0].selected && "white",
                                                ":hover": {
                                                    backgroundColor: "#4caf50",
                                                    color: "white"
                                                }
                                            }}
                                        />
                                        <Chip
                                            label="Medium"
                                            onClick={() => { handlePriorityFilter(1, 1) }}
                                            sx={{
                                                backgroundColor: priority[1].selected && '#ef9800',
                                                color: priority[1].selected && 'white',
                                                ":hover": {
                                                    backgroundColor: '#ef9800',
                                                    color: "white"
                                                }
                                            }}
                                        />
                                        <Chip
                                            label="High"
                                            onClick={() => { handlePriorityFilter(2, 2) }}
                                            sx={{
                                                backgroundColor: priority[2].selected && "#ef5350",
                                                color: priority[2].selected && 'white',
                                                ":hover": {
                                                    backgroundColor: "#ef5350",
                                                    color: "white"
                                                }
                                            }}
                                        />
                                    </> :
                                    <Grid
                                        item
                                        xs={6} sm={6} md={6} lg={6}
                                    >
                                    </Grid>
                            }
                            <Typography sx={{
                                fontSize: "12px",
                                fontWeight: "bold"
                            }}>
                                eBilling Type :
                            </Typography>
                            <Chip
                                label="EB"
                                onClick={() => { handleBillingTypeFilter("EB", "EB") }}
                                sx={{
                                    backgroundColor: billingType[0].selected && "#2196f3",
                                    color: billingType[0].selected && "white",
                                    ":hover": {
                                        backgroundColor: "#2196f3",
                                        color: "white"
                                    }
                                }}
                            />
                            <Chip
                                label="EA"
                                onClick={() => { handleBillingTypeFilter("EA", "EA") }}
                                sx={{
                                    backgroundColor: billingType[1].selected && '#2196f3',
                                    color: billingType[1].selected && 'white',
                                    ":hover": {
                                        backgroundColor: '#2196f3',
                                        color: "white"
                                    }
                                }}
                            />
                            <Box
                                onMouseEnter={() => setHomeHovered(true)}
                                onMouseLeave={() => setHomeHovered(false)}
                                sx={{
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    cursor: 'pointer'
                                }}
                            >
                                {
                                    isInfoHovered ?
                                        <Tooltip title="The invoice list does not include completed invoices. Please apply a filter to view those records." placement='left-start'>
                                            <InfoIcon
                                                sx={{
                                                    color: '#2196f3'
                                                }}
                                            />
                                        </Tooltip>
                                        :
                                        <InfoOutlinedIcon
                                            sx={{
                                                color: '#2196f3'
                                            }}
                                        />
                                }
                            </Box>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid>
            {
                Action &&
                <CustomizedSnackbars
                    message={message}
                    snackstatus={Action}
                    onCloseCallback={handleSnapClose}
                    snacktype={snackType}
                />
            }
            {
                isDialog &&
                <ResponsiveDialog
                    value={dialogvalue}
                    Title={dialogTitle}
                    isConfirmDialog={confirmdialog}
                    onsavecallback={handlefinalLabelChange}
                    oncancelcallback={handlefinalLabelChange}
                    passSelectedInvoice={selectedinvoice}
                    isViewOnly={isView}
                />
            }
            {
                isStatusChange && (
                    <StatusDialog
                        displayInfo={dialogvalue}
                        onsavecallback={handlefinalStatusChange}
                        oncancelcallback={handlefinalStatusChange}
                        statusInfo={statusPayload}
                        passSelectedInvoice={selectedinvoice}
                        disputeInfo={disputeInfo}
                    />
                )
            }
        </>
    )
}

export default InvoicePage
