/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import UnauthorizedComponent from '../unauthorized';
import { loginRequest } from '../../authConfig';

/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
function RouteGuard({ ...props }) {
    const { instance } = useMsal();
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    const currentAccount = instance.getActiveAccount();

    const authRequest = {
        ...loginRequest,
    };

    const onLoad = async () => {
        if (currentAccount?.idTokenClaims?.roles) {

            const intersection = props.roles
                .filter((role) => currentAccount.idTokenClaims.roles.includes(role));

            if (intersection.length > 0) {
                setIsAuthorized(true);
            }
        }
    };

    useEffect(() => {
        if (currentAccount !== null && firstLoad === true) {
            setFirstLoad(false);
        }
        onLoad();
    }, [instance, currentAccount]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
        >
            {isAuthorized ? (
                <div>{props.children}</div>
            ) : (
                firstLoad ?
                    <></>
                    :
                    <UnauthorizedComponent />
            )}
        </MsalAuthenticationTemplate>
    );
}

export default RouteGuard

