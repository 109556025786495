/* eslint-disable react/prop-types */
import React, { useContext } from 'react'
import { InvoiceContext } from '../../context/invoiceContext'
import { Grid, Box } from '@mui/material'
import StallionLogo from '../../assets/stallion_infrastructure_services_logo.jpg';
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes.json";

export default function UnauthorizedComponent({
    message
}) {
    const { signedInUser } = useContext(InvoiceContext);
    const navigate = useNavigate();
    const onClickHandler = () => {
        if (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers")) {
            navigate(routes?.HOME);
        }
        else {
            navigate(routes?.INVOICE);
        }
    };

    return (
        <>
            <Grid
                container
                style={{
                    position: 'relative',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 440,
                    width: "100%"
                }}>
                <Box
                    component="img"
                    sx={{
                        width: '10%',
                        opacity: 0.5,
                        border: '1px solid white',
                        borderRadius: '100px'
                    }}
                    alt="Stallion Infrastructure"
                    src={StallionLogo}
                />
                {
                    message ?
                        <div style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                            {message}
                        </div> :
                        <div style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>
                            You are unauthorized to view this content. <span style={{ color: 'rgb(33 150 243)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onClickHandler()}>Click here</span> to navigate to home page.</div>
                }
            </Grid>
        </>
    )
}