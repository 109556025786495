
import { LogLevel } from "@azure/msal-browser";

export const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID, // This is the ONLY mandatory field that you need to supply.
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_TENANT_ID}`, // Defaults to "https://login.microsoftonline.com/common"
        redirectUri: `${process.env.REACT_APP_APP_URL}`, // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/login', // Indicates the page to navigate after logout.
        clientCapabilities: ['CP1'], // this lets the resource owner know that this client is capable of handling claims challenge.
    },
    cache: {
        cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                }
            },
        },
    },
};
export const protectedResources = {
    scopes: [
        process.env.REACT_APP_SCOPE],
};
export const loginRequest = {
    scopes: [...protectedResources.scopes],
};

export const appRoles = {
    TreasuryUsers: "TreasuryUsers",
    RegionalUsers: "RegionalUsers"
}
