/* eslint-disable react/prop-types */
import React from 'react'
import { Typography, Card, Grid } from '@mui/material'
import BarChart from '../../components/graph'
import LineBar from '../../components/graph/lineGraph'

function GraphLayout({
    chartData,
    disputeVolumeData,
    averageDispute
}) {
    return (
        <Grid
            container
            sx={{
                marginTop: '10px',
                height: '75%'
            }}
        >
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    gap: "10px",
                    height: '50%'
                }}
            >
                {
                    chartData?.map((graph, index) => {
                        if (graph.index <= 3) {
                            return (
                                <Grid
                                    item
                                    xs={12} sm={3.9} md={3.9} lg={3.9}
                                    key={index}
                                    sx={{
                                        height: '100%'
                                    }}
                                >
                                    <Card sx={{
                                        backgroundColor: (theme) => theme.palette.primary.main,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        color: 'white',
                                    }}>
                                        <Typography sx={{ fontSize: 14 }}>
                                            {graph?.graphTitle}
                                        </Typography>
                                    </Card>
                                    <BarChart
                                        graphTitle={graph?.graphTitle}
                                        Data={graph?.data}
                                        XLabels={graph?.xLabels}
                                        YLabel={graph?.yLabel}
                                    />
                                </Grid>
                            )
                        }
                        return (<></>)
                    }
                    )
                }
            </Grid>
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    gap: "10px",
                    height: '50%',
                    marginTop: '30px',
                }}
            >
                {
                    chartData?.map((graph, index) => {
                        if (graph.index > 3) {
                            return (
                                graph.isColumnChart && (
                                    <Grid
                                        item
                                        xs={12} sm={3.9} md={3.9} lg={3.9}
                                        key={index}
                                        sx={{
                                            height: '100%'
                                        }}
                                    >
                                        <Card sx={{
                                            backgroundColor: (theme) => theme.palette.primary.main,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: 'white'
                                        }}>
                                            <Typography sx={{ fontSize: 14 }}>
                                                {graph?.graphTitle}
                                            </Typography>
                                        </Card>
                                        <BarChart
                                            graphTitle={graph?.graphTitle}
                                            Data={graph?.data}
                                            XLabels={graph?.xLabels}
                                            YLabel={graph?.yLabel}
                                        />
                                    </Grid>)
                            )
                        }
                        return (<></>)
                    })
                }
                <Grid item xs={12} sm={3.9} md={3.9} lg={3.9} sx={{
                    height: '100%'
                }}>
                    <Card sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'white'
                    }}>
                        <Typography sx={{ fontSize: 14 }}>
                            {disputeVolumeData?.graphTitle}
                        </Typography>
                    </Card>
                    <BarChart
                        graphTitle={disputeVolumeData?.graphTitle}
                        Data={disputeVolumeData?.data}
                        XLabels={disputeVolumeData?.xLabels}
                        YLabel={disputeVolumeData?.yLabel}
                    />
                </Grid>
                <Grid item xs={12} sm={3.9} md={3.9} lg={3.9} sx={{
                    height: '100%'
                }}>
                    <Card sx={{
                        backgroundColor: (theme) => theme.palette.primary.main,
                        display: 'flex',
                        justifyContent: 'center',
                        color: 'white'
                    }}>
                        <Typography sx={{ fontSize: 14 }}>
                            {averageDispute?.graphTitle}
                        </Typography>
                    </Card>
                    <LineBar
                        min={averageDispute?.minimum}
                        max={averageDispute?.maximum}
                        avg={averageDispute?.average}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default GraphLayout
