import React, { useContext } from 'react'
import { InvoiceContext } from '../../context/invoiceContext'
import { Grid, Box } from '@mui/material'
import StallionLogo from '../../assets/stallion_infrastructure_services_logo.jpg';
import { useNavigate } from "react-router-dom";
import routes from "../../constants/routes.json";

function PageNotFound() {
    const { signedInUser } = useContext(InvoiceContext);
    const navigate = useNavigate();
    const onClickHandler = (path) => {
        navigate(path);
    };

    return (
        <>
            <Grid
                container
                style={{
                    position: 'relative',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 440,
                    width: "100%"
                }}>
                <Box
                    component="img"
                    sx={{
                        width: '10%',
                        opacity: 0.5,
                        border: '1px solid white',
                        borderRadius: '100px'
                    }}
                    alt="Stallion Infrastructure"
                    src={StallionLogo}
                />
                <div style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: '20px' }}>The requested page was not found. <span style={{ color: 'rgb(33 150 243)', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? onClickHandler(routes?.HOME) : onClickHandler(routes?.INVOICE)}>Click here</span> to navigate to home page.</div>
            </Grid>
        </>
    )
}

export default PageNotFound