import React from 'react'
import ResponsiveAppBar from '../../components/appbar';
import DetachedSidebar from '../../components/sidebar';
import { Grid } from '@mui/material';

// eslint-disable-next-line react/prop-types
function AppLayout({ screen, screenTitle }) {

    return (
        <>
            <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                <DetachedSidebar />
                <Grid
                    container
                    pl={9}
                    pr={0.8}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                    height='100vh'
                >
                    <Grid height='30px'>
                        <ResponsiveAppBar screenTitle={screenTitle} isLoginPage={false} />
                    </Grid>
                    <Grid sx={{ height: "calc(100% - 60px)", transition: 'padding-left 0.3s ease-in-out' }}>
                        {screen}
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default AppLayout;
