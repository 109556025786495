/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

export default function CustomizedSnackbars({
    message,
    snackstatus,
    onCloseCallback,
    snacktype
}) {
    const [open, setOpen] = useState(snackstatus);

    const handleClose = (event, reason, value) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
        if (onCloseCallback) {
            onCloseCallback(value);
        }
    };

    return (
        <>
            <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                <Alert
                    onClose={(event, reason) => handleClose(event, reason, false)}
                    severity={snacktype}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {message}
                </Alert>
            </Snackbar>
        </>
    );
}
