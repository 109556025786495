/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box, Typography, Input, Tooltip, TextField, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomizedSnackbars from '../snackbar';
import { validateInput } from '../../utils';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function ResponsiveDialog({
    Title,
    value,
    onsavecallback,
    oncancelcallback,
    isConfirmDialog,
    passSelectedInvoice,
    isViewOnly,
}) {
    const [open, setOpen] = useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [labelInput, setLabelInput] = useState();
    const [chipData, setChipData] = useState([]);
    const [action, setAction] = useState(false);
    const [Message, SetMessage] = useState("")
    const [snacktype, setSnacktype] = useState("");
    const [ErrorMessage, setErrorMessage] = useState(false);
    const [editMode, setEditMode] = useState({});
    const [originalValues, setOriginalValues] = useState({});

    const handleClose = () => {
        setOpen(false);
        if (oncancelcallback) {
            oncancelcallback(false);
        }
    };

    const handleconfirm = () => {
        if (!Object.values(editMode).some(value => value)) {
            if ((passSelectedInvoice.invoiceLabels.length !== 0) || (chipData.length > 0)) {
                const apply = validateLabels(chipData);
                if (apply) {
                    if (onsavecallback) {
                        handleClose();
                        onsavecallback(true, chipData, passSelectedInvoice);
                    }
                }
            }
        }
    };

    const handledialogconfirm = () => {
        if (onsavecallback) {
            handleClose();
            onsavecallback(true);
        }
    }

    const validateLabels = (chipData) => {
        const hasEmptyLabel = chipData.some(element => {
            if (element.name.length === 0) {
                setAction(true);
                setSnacktype("warning");
                SetMessage("Labels should not be empty.");
                return true;
            }
            if (!validateInput(element.name)) {
                setAction(true);
                setSnacktype("warning");
                SetMessage(`Error: Label must only contain letters, numbers, "-", "_", or "."`);
                return true;
            }
            return false;
        });
        return !hasEmptyLabel;
    }

    const handlecancel = () => {
        if (oncancelcallback) {
            handleClose();
            oncancelcallback(false);
        }
    };

    useEffect(() => {
        if (!isConfirmDialog) {
            constructChipData();
        }
    }, []);

    const constructChipData = () => {
        const updatedChipData = passSelectedInvoice.invoiceLabels.map((chip, index) => ({
            key: index,
            name: chip,
        }));
        setChipData(updatedChipData);
    };

    const handleDelete = (chipToDelete) => () => {
        setChipData((chips) =>
            chips.filter((chip) => chip.key !== chipToDelete.key)
        );
    };

    const handleLabelEditChange = (event, index) => {
        const newData = [...chipData];
        if (event.target.value.length < 25) {
            newData[index].name = event.target.value;
            setChipData(newData);
        }
        else {
            setAction(true);
            setSnacktype("warning");
            SetMessage("Labels should be less than 25 characters.");
        }
    };

    const handleInputLabel = (event) => {
        if (event.target.value.length < 25) {
            setErrorMessage(false);
            setLabelInput(event.target.value);
        }
        else {
            setErrorMessage(true);
            SetMessage("Labels should be less than 25 characters.");
        }
    };

    const handleLabelAdd = () => {
        if (labelInput) {
            if (validateInput(labelInput)) {
                if (!checkLabelDuplicate(labelInput)) {
                    const lastKey = chipData.length > 0 ? Math.max(...chipData.map(chip => chip.key)) : 0;
                    const newData = [...chipData, { key: lastKey + 1, name: labelInput }];
                    setChipData(newData);
                    setLabelInput("");
                    setErrorMessage(false);
                }
                else {
                    SetMessage("Duplicate label.");
                    setErrorMessage(true);
                    setTimeout(() => {
                        setErrorMessage(false);
                    }, 2000);
                }
            }
            else {
                SetMessage(`Error: Label must only contain letters, numbers, "-", "_", or "."`);
                setErrorMessage(true);
                setTimeout(() => {
                    setErrorMessage(false);
                }, 2000);
            }
        }
        else {
            setErrorMessage(true);
            SetMessage("Label cannot be empty.");
            setTimeout(() => {
                setErrorMessage(false);
            }, 2000);
        }
    };

    const checkLabelDuplicate = (input) => {
        const hasDuplication = chipData.some(element => {
            if (element.name === input) {
                return true;
            }
            return false;
        });
        return hasDuplication;
    }

    const handleEditClick = (index) => {
        setOriginalValues((prevOriginalValues) => ({
            ...prevOriginalValues,
            [index]: chipData[index].name
        }));
        setEditMode(() => {
            const newEditMode = {};
            chipData.forEach((_, i) => {
                newEditMode[i] = i === index;
            });
            return newEditMode;
        });
    };

    const handleDone = (index) => {
        const hasDuplication = chipData.some(element => {
            if ((element.name === chipData[index].name) && (element.key !== chipData[index].key)) {
                return true;
            }
            return false;
        });
        if (hasDuplication) {
            const newData = [...chipData];
            newData[index].name = originalValues[index];
            setChipData(newData);
            setAction(true);
            setSnacktype("warning");
            SetMessage("Duplicate label.");
        }
        if (chipData[index].name === "") {
            const newData = [...chipData];
            newData[index].name = originalValues[index];
            setChipData(newData);
            setAction(true);
            setSnacktype("warning");
            SetMessage("Label cannot be empty.");
        }
        if (!validateLabels(chipData)) {
            const newData = [...chipData];
            newData[index].name = originalValues[index];
            setChipData(newData);
        }
        setEditMode((prevEditMode) => ({
            ...prevEditMode,
            [index]: false
        }));
    };

    const handleClear = (index) => {
        const newData = [...chipData];
        newData[index].name = originalValues[index];
        setChipData(newData);
        setEditMode((prevEditMode) => ({
            ...prevEditMode,
            [index]: false
        }));
    };

    const handleSnapClose = (value) => {
        setAction(value);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '20px',
                },
            }}
        >
            {isConfirmDialog ? (
                <Box p={3.5}>
                    <DialogTitle mb={2} sx={{ padding: 0 }}>
                        <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                            {Title}
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                marginTop: '15px',
                            }}
                        >
                            {value}
                        </Typography>
                    </DialogTitle>
                    <DialogActions sx={{ padding: 0, marginTop: '25px' }}>
                        <Button
                            onClick={handlecancel}
                            sx={{
                                textTransform: 'capitalize',
                                width: '60px',
                                height: '30px',
                                borderRadius: '20px',
                                fontSize: '15px',
                                marginLeft: '5px',
                                color: (theme) => theme.palette.primary.main,
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                    color: 'white',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            autoFocus
                            onClick={handledialogconfirm}
                            sx={{
                                textTransform: 'capitalize',
                                backgroundColor: (theme) => theme.palette.primary.main,
                                width: '120px',
                                height: '30px',
                                borderRadius: '20px',
                                color: '#FFF',
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                },
                            }}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Box>
            ) : (
                <Box p={2.5} sx={{
                    overflowY: 'auto',
                    minWidth: 300,
                    maxWidth: 300,
                }}>
                    <DialogTitle mb={2} sx={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                            {Title}
                        </Typography>
                        <IconButton edge="end" onClick={handleClose}
                            disableRipple={true}
                            sx={{
                                fontSize: '15px'
                            }}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <Box sx={{
                        display: 'flex',
                    }}>
                        <TextField
                            value={labelInput}
                            autoFocus={true}
                            onChange={handleInputLabel}
                            InputProps={{ disableUnderline: true }}
                            placeholder="Type here to add labels"
                            size="small"
                            sx={{
                                fontSize: '14px',
                                border: `2px solid ${ErrorMessage ? '#f44336' : 'black'}`,
                                width: '80%',
                                borderRadius: '20px',
                                '& .MuiOutlinedInput-root': {
                                    fontSize: '14px',
                                    '& fieldset': {
                                        border: 'none',
                                    },
                                    '&:hover fieldset': {
                                        border: 'none',
                                    }
                                },
                            }}
                        />
                        <Tooltip title="Add" placement='bottom'>
                            <Button
                                onClick={handleLabelAdd}
                                sx={{
                                    backgroundColor: (theme) => theme.palette.primary.main,
                                    border: '1px solid white',
                                    marginLeft: '1%',
                                    borderRadius: '20px',
                                    color: '#FFF',
                                    ':hover': {
                                        backgroundColor: (theme) => theme.palette.primary.whitish,
                                    },
                                }}
                            >
                                <AddIcon />
                            </Button>
                        </Tooltip>
                    </Box>
                    {
                        ErrorMessage ?
                            <Box sx={{
                                height: '35px',
                                display: 'flex',
                            }}>
                                <Typography sx={{
                                    color: (theme) => theme.palette.error.main,
                                    fontSize: '12px',
                                    marginLeft: '5px',
                                }}>
                                    {Message}
                                </Typography>
                            </Box> :
                            <Box sx={{ height: '35px' }} />
                    }
                    <Box
                        p={1}
                        sx={{
                            border: '2px solid black',
                            borderRadius: '5px',
                            overflowY: 'auto',
                            height: 250,
                        }}>
                        {(chipData.length > 0) ? (
                            chipData.map((data, index) => (
                                <Box
                                    p={0.5}
                                    key={index}
                                    sx={{
                                        marginTop: '5px',
                                        width: '95%',
                                        border: editMode[index] ? `1px solid #2196f3` : `1px solid black`,
                                        borderRadius: '10px',
                                        height: 30,
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            display: 'flex',
                                        }}>
                                        {
                                            editMode[index] ? (
                                                <>
                                                    <Input
                                                        value={data.name}
                                                        disableUnderline={true}
                                                        autoFocus={true}
                                                        onChange={(event) => handleLabelEditChange(event, index)}
                                                        sx={{
                                                            width: '100%',
                                                            fontSize: '12px',
                                                            color: ' #2196f3',
                                                        }}
                                                    />
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Tooltip title="Save">
                                                            <DoneIcon
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    cursor: 'pointer',
                                                                    color: 'green'
                                                                }}
                                                                onClick={() => handleDone(index)}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Cancel">
                                                            <CloseIcon
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    cursor: 'pointer',
                                                                    color: 'red'
                                                                }}
                                                                onClick={() => handleClear(index)}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Typography sx={{
                                                        fontSize: '12px',
                                                        width: '100%'
                                                    }}>
                                                        {data.name}
                                                    </Typography>
                                                    <Box sx={{
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}>
                                                        <Tooltip title="Edit">
                                                            <EditIcon
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => handleEditClick(index)}
                                                            />
                                                        </Tooltip>
                                                        <Tooltip title="Delete">
                                                            <DeleteIcon
                                                                sx={{
                                                                    fontSize: '14px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={handleDelete(data)}
                                                            />
                                                        </Tooltip>
                                                    </Box>
                                                </>
                                            )
                                        }
                                    </Box>
                                </Box>
                            ))
                        ) : (
                            <Typography ml={10} mt={12}>
                                No Records Found
                            </Typography>
                        )
                        }
                    </Box>
                    <DialogActions sx={{
                        justifyContent: 'flex-end',
                        display: 'flex',
                        marginTop: '5px'
                    }}>
                        <Button
                            onClick={handleconfirm}
                            sx={{
                                textTransform: 'capitalize',
                                backgroundColor: (theme) => theme.palette.primary.main,
                                width: '120px',
                                height: '30px',
                                borderRadius: '20px',
                                color: '#FFF',
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                },
                            }}
                        >
                            Save
                        </Button>
                        <Button
                            onClick={handlecancel}
                            sx={{
                                textTransform: 'capitalize',
                                width: '60px',
                                height: '30px',
                                borderRadius: '20px',
                                fontSize: '15px',
                                marginLeft: '5px',
                                color: (theme) => theme.palette.primary.main,
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                    color: 'white',
                                },
                            }}
                        >
                            Cancel
                        </Button>
                    </DialogActions>
                </Box>
            )
            }
            {
                action &&
                <CustomizedSnackbars
                    message={Message}
                    snacktype={snacktype}
                    snackstatus={action}
                    onCloseCallback={handleSnapClose}
                />
            }
        </Dialog >
    );
}

