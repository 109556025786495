/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { InvoiceContext } from '../../context/invoiceContext'
import { IconButton, OutlinedInput, Box, Tooltip, Link, Menu, MenuItem } from "@mui/material";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import ResponsiveDialog from '../../components/dialog';
import CustomizedSnackbars from '../../components/snackbar';
import StatusDialog from '../../components/statusDialog';
import LoaderComponent from '../../components/loader';
import PageNotFound from '../pagenotfound';
import UnauthorizedComponent from '../../components/unauthorized';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import WarningIcon from '@mui/icons-material/Warning';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ModeCommentOutlinedIcon from '@mui/icons-material/ModeCommentOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from "react-router-dom";
import routes from "../../constants/routes.json";
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import './styles.css';
// Data Imports
import StatusData from '../../constants/Status_Data.json';
import StatusMapping from '../../constants/statusMapping.json';
import StatusMappingEA from '../../constants/statusMappingEA.json';
// Api Import 
import { getInvoiceDetails, getInvoiceActivity, addComment, updateInvoiceLabel, updateStatus, getAllDisputeCodes } from '../../services/api';

// Function Import
import { ConvertUTCToLocalTime, stringReducer, ConvertUTCToLocalDate } from '../../utils';


function InvoiceDetails() {
    const { signedInUser,
        InitializeStatus,
        highlightInvoice,
        InitializeHighlightInvoice,
        selectedIndex,
        InitializeIndex,
        InitializeNextInvoice,
        nextInvoice,
        nextIndex,
        InitializeNextIndex } = useContext(InvoiceContext);
    const [invoiceDetails, setInvoiceDetails] = useState();
    const [invoiceActivity, setInvoiceActivity] = useState();
    const [commentPayload, setCommentPayload] = useState();
    const [loading, setIsLoading] = useState(true);
    const [activityLoading, setIsActivityLoading] = useState(true);
    const [IsActivityChange, SetIsActivityChange] = useState(false);
    const [IsStatusChange, SetIsStatusChange] = useState(false);
    const [activeActivityTab, SetActiveActivityTab] = useState(signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ? 0 : 1);
    const activityTabs = ["All", "Comments", "History"];
    const [commentText, setCommentText] = useState("");
    const [isDialog, SetIsDialog] = useState(false);
    const [dialogvalue, setDialogvalue] = useState("");
    const [confirmdialog, setConfirmDialog] = useState(false);
    const [isView, setIsView] = useState(false);
    const [Action, SetAction] = useState(false)
    const [message, setMessage] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [statusData, setStatusData] = useState(StatusData);
    const [statusPayload, setStatusPayload] = useState();
    const [isStatusChange, setStatusChange] = useState(false);
    const [errorCode, setErrorCode] = useState(null);
    const [snacktype, setSnackType] = useState("success");
    const [disputeInfo, setDisputeInfo] = useState([]);
    const { invoice } = useParams();
    const open = Boolean(anchorEl);
    const [sortActivityAsc, SetSortActivityAsc] = useState(false);
    const [activityPayload, SetActivityPayload] = useState({
        invoiceNumber: invoice,
        sortAscending: sortActivityAsc
    })
    const [refresh, setRefresh] = useState(false);

    const navigate = useNavigate();
    const onClickHandler = (path) => {
        navigate(path);
    };

    useEffect(() => {
        setIsLoading(true);
        getInvoiceDetails(invoice)
            .then((res) => {
                setInvoiceDetails(res)
            })
            .catch((err) => {
                setErrorCode(err.status);
                if (err.status === 422) {
                    setTimeout(() => {
                        navigate(routes?.INVOICE);
                    }, 2000);
                }
            })
            .finally(() => setIsLoading(false));
    }, [IsStatusChange, invoice, refresh])

    useEffect(() => {
        setIsActivityLoading(true);
        getInvoiceActivity(activityPayload)
            .then((res) => {
                setInvoiceActivity(res)
            })
            .catch((_err) => {
                setSnackType("error");
                SetAction(true);
                setMessage("Error retrieving invoice activity. Refresh the page or contact System Administrator if the issue persists.");
            })
            .finally(() => setIsActivityLoading(false));
    }, [IsActivityChange, activityPayload, refresh])

    useEffect(() => {
        if (activityPayload.sortAscending !== sortActivityAsc) {
            SetActivityPayload({
                ...activityPayload,
                sortAscending: sortActivityAsc
            })
        }
    }, [sortActivityAsc])

    useEffect(() => {
        if (commentPayload != null) {
            addComment(commentPayload)
                .then((res) => {
                    setCommentText("");
                    SetAction(true);
                    setMessage("Comment added successfully.");
                    setSnackType("success");
                })
                .catch((err) => {
                    setSnackType("error");
                    SetAction(true);
                    setMessage(err.message);
                })
                .finally(() => SetIsActivityChange(!IsActivityChange));
        }
    }, [commentPayload])

    const handleComment = (event) => {
        setCommentText(event.target.value)
    }

    const addCommentClicked = () => {
        if (commentText.trim() !== "") {
            setCommentPayload(
                {
                    invoiceNumber: invoice,
                    comments: commentText
                }
            )
        }
        else {
            SetAction(true);
            setMessage("Comment cannot be empty.");
            setSnackType("warning");
            setCommentText("");
        }
    };

    const handleSortClicked = () => {
        SetSortActivityAsc(prv => !prv)
    }

    const handleEditLabel = (value) => {
        SetIsDialog(value)
        setConfirmDialog(false)
        setDialogvalue(`Add Label`)
        setIsView(false)
    }

    const handlefinalLabelChange = (value, newLabels, Inv) => {
        if (value) {
            SetIsDialog(false)
            const labelpayload = newLabels.map((ind) => {
                return ind.name
            });
            const payload = {
                invoiceNumber: Inv.invoiceNumber,
                labels: labelpayload
            }
            if (payload) {
                updateInvoiceLabel(payload).then((response) => {
                    if (response.isSuccessful) {
                        SetAction(true);
                        setSnackType("success");
                        setMessage("Label updated successfully");
                        SetIsStatusChange(prv => !prv);
                    }
                }).catch((err) => {
                    SetAction(true);
                    setSnackType("error");
                    setMessage(err.message)
                })
            }
        }
        else {
            SetIsDialog(false)
        }

    }

    const handleSnapClose = (value) => {
        SetAction(value);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleStatusChange = (event) => {
        setAnchorEl(null);
        setStatusPayload(event.target.selected)
        setStatusChange(true);
        setDialogvalue(`Do you want to change the status of Invoice Number ${invoiceDetails?.invoiceNumber} - to "${event.target.selected?.Name}"`);
        getAllDisputeCodes().then((response) => {
            if (response) setDisputeInfo(response)
        }).catch((_err) => {
            setSnackType("error");
            SetAction(true);
            setMessage("Error retrieving dispute codes. Refresh the page or contact System Administrator if the issue persists.");
        })
    };

    const handlefinalStatusChange = (value, comment, labels, selectedDispute) => {
        if (value) {
            setStatusChange(false)
            const payLabels = labels.map((lab) => {
                return lab.name;
            });
            const bodyParams = {
                invoiceNumber: invoiceDetails?.invoiceNumber,
                disputeCodeId: selectedDispute?.Id,
                statusId: statusPayload?.Id,
                comments: comment,
                labels: payLabels,
            };
            updateStatus(bodyParams).then((response) => {
                if (response.isSuccessful) {
                    SetIsStatusChange(prv => !prv);
                    SetIsActivityChange(prv => !prv);
                    SetAction(true);
                    setMessage("Status Updated Successfully");
                    InitializeHighlightInvoice(nextInvoice);
                    InitializeIndex(nextIndex);
                    InitializeNextInvoice(null);
                    InitializeIndex(0);
                }
            }).catch((err) => {
                setSnackType("error");
                SetAction(true);
                setMessage(err.message);
            });


        }
        else {
            setStatusChange(false)
        }
    }

    const constructStatus = () => {
        if (invoiceDetails != null) {
            if (invoiceDetails?.billingType === 'EB') {
                StatusMapping.forEach((Istatus) => {
                    if (Istatus.invStatus.statusId === invoiceDetails?.currentStatusId) {
                        setStatusData(Istatus.Mapping);
                    }
                })
            }
            else {
                StatusMappingEA.forEach((Istatus) => {
                    if (Istatus.invStatus.statusId === invoiceDetails?.currentStatusId) {
                        setStatusData(Istatus.Mapping);
                    }
                })
            }
        }
    }

    useEffect(() => {
        constructStatus();
    }, [invoiceDetails]);

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: 'gainsboro'
        },
        [`& .${tooltipClasses.arrow}`]: {
            color: 'gainsboro'
        }
    }));

    return (
        <div style={{
            height: '100%'
        }}>
            {!loading ?
                (
                    invoiceDetails != null && errorCode === null ?
                        (<div className='inv-details-overall'>
                            <div className='inv-details-main-header'>
                                <span
                                    className='navigation'
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                    {
                                        signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") &&
                                        <>
                                            <Tooltip title="Home">
                                                <HomeIcon onClick={() => onClickHandler(routes?.HOME)} sx={{
                                                    cursor: 'pointer'
                                                }} />
                                            </Tooltip>
                                            {"/"}&nbsp;
                                        </>
                                    }
                                    <span className='nav-text'>
                                        <a className='nav-link' onClick={() => onClickHandler(routes?.INVOICE)}>Invoices</a> / {invoice}
                                    </span>
                                </span>
                            </div>
                            <div className='invoice-details-outer-container'>
                                <div className='invoice-details-header'>
                                    <span className='invoice-number'>Invoice Number {invoiceDetails?.invoiceNumber}</span>
                                    <span className='invoice-status'>{invoiceDetails?.invoiceStatus}</span>
                                    {(invoiceDetails?.billingType !== null && invoiceDetails?.billingType) &&
                                        <span className='billing-type'>{invoiceDetails?.billingType}</span>
                                    }
                                    {
                                        (invoiceDetails?.isInternalDisputeResolved) ? (
                                            <Tooltip title="High Priority">
                                                <WarningIcon sx={{ color: 'red', verticalAlign: 'bottom', marginLeft: '5px' }} />
                                            </Tooltip>
                                        )
                                            :
                                            (
                                                (invoiceDetails?.isPriority) &&
                                                <Tooltip title="Medium Priority">
                                                    <WarningAmberIcon sx={{ color: 'rgb(239, 152, 0)', verticalAlign: 'bottom', marginLeft: '5px' }} />
                                                </Tooltip>
                                            )
                                    }
                                    {
                                        (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") && invoiceDetails?.invoiceStatus !== "Completed") &&
                                        <span className='status-change'>
                                            <Box>
                                                <Tooltip title="Change Status">
                                                    <IconButton
                                                        onClick={handleClick}
                                                        sx={{
                                                            verticalAlign: '-webkit-baseline-middle'
                                                        }}
                                                    >
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Menu
                                                    anchorEl={anchorEl}
                                                    open={open}
                                                    onClose={handleClose}
                                                    PaperProps={{
                                                        style: {
                                                            backgroundColor: 'white',
                                                            boxShadow: 'none',
                                                        },
                                                    }}
                                                >
                                                    {statusData.map((indiStatus) => (
                                                        <MenuItem
                                                            key={indiStatus.statusId}
                                                            selected={
                                                                { Id: indiStatus.statusId, Name: indiStatus.statusName }
                                                            }
                                                            onClick={handleStatusChange}
                                                            sx={{
                                                                fontSize: 10
                                                            }}
                                                        >
                                                            {indiStatus.statusName}
                                                        </MenuItem>
                                                    ))}
                                                </Menu>
                                            </Box>
                                        </span>
                                    }
                                    <span className='status-Refresh'>
                                        <Box>
                                            <Tooltip title="Refresh">
                                                <IconButton
                                                    onClick={() => { setRefresh(prv => !prv); }}
                                                    sx={{
                                                        verticalAlign: '-webkit-baseline-middle'
                                                    }}
                                                >
                                                    <AutorenewOutlinedIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    </span>
                                </div>
                                <div>
                                    <div className='invoice-details-container'>
                                        <div className='invoice-details'>
                                            <span className='invoice-details-column'>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Customer ID</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.customerId}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Customer Name</span>
                                                    <span className='inv-detail-field-val'>
                                                        <Tooltip title={invoiceDetails?.customerName}>
                                                            {stringReducer(invoiceDetails?.customerName, 30)}
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Company</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.companyCode}{invoiceDetails?.companyCode !== null && invoiceDetails?.companyCode !== "" && invoiceDetails?.companyName !== null && invoiceDetails?.companyName !== "" ? "-" : ""}{invoiceDetails?.companyName}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>eBill System</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.eBillingSystem}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Labels</span>
                                                    <span className='inv-detail-field-val'>
                                                        {<Box className='labels-container' sx={{ display: 'flex', alignItems: 'center' }}>
                                                            {invoiceDetails?.invoiceLabels.map((data, index) => {
                                                                if (index < 3) {
                                                                    return (
                                                                        <>
                                                                            <Tooltip title={data}>
                                                                                <Chip
                                                                                    className='label-chip'
                                                                                    key={index}
                                                                                    size='small'
                                                                                    sx={{
                                                                                        marginLeft: 1,
                                                                                        fontSize: 10,
                                                                                        cursor: 'default'
                                                                                    }}
                                                                                    label={stringReducer(data, 5)}
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    );
                                                                }
                                                                return (<></>)
                                                            })}
                                                            {
                                                                (invoiceDetails?.invoiceLabels.length > 3) && (
                                                                    <LightTooltip
                                                                        placement='top-start'
                                                                        title={
                                                                            <Box style={{ maxWidth: '300px', overflow: 'auto', padding: '1px' }}>
                                                                                {invoiceDetails?.invoiceLabels.map((data, index) => (
                                                                                    index > 2 ? (
                                                                                        <Chip
                                                                                            key={index}
                                                                                            size="small"
                                                                                            sx={{
                                                                                                fontSize: '10px',
                                                                                                color: 'black',
                                                                                                backgroundColor: '#ebebeb',
                                                                                                cursor: 'pointer',
                                                                                                display: 'block',
                                                                                                marginBottom: '4px',
                                                                                            }}
                                                                                            label={data}
                                                                                        />
                                                                                    ) : null
                                                                                ))}
                                                                            </Box>
                                                                        }
                                                                    >
                                                                        <Link
                                                                            sx={{ marginLeft: 1, fontSize: 10, cursor: 'pointer', color: 'rgb(33 150 243)' }}
                                                                            underline='none'
                                                                        >
                                                                            {`+${invoiceDetails?.invoiceLabels.length - 3}`}
                                                                        </Link>
                                                                    </LightTooltip>
                                                                )
                                                            }
                                                            <Box sx={{ flexGrow: 1 }} />
                                                            {
                                                                (signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") && invoiceDetails?.billingType === 'EB') ?
                                                                    invoiceDetails?.invoiceLabels.length > 0 ?
                                                                        <Tooltip title="Edit">
                                                                            <IconButton className='label-edit-icon'
                                                                                sx={{
                                                                                    '&.MuiButtonBase-root:hover': {
                                                                                        bgcolor: 'transparent',
                                                                                    },
                                                                                }}
                                                                                disableRipple
                                                                                onClick={() => { handleEditLabel(true) }}
                                                                            >
                                                                                <EditIcon style={{ fontSize: '20px' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        :
                                                                        <Tooltip title="Add">
                                                                            <IconButton className='label-edit-icon'
                                                                                sx={{
                                                                                    '&.MuiButtonBase-root:hover': {
                                                                                        bgcolor: 'transparent',
                                                                                    },
                                                                                }}
                                                                                disableRipple
                                                                                onClick={() => { handleEditLabel(true) }}
                                                                            >
                                                                                <AddCircleOutlineIcon style={{ fontSize: '20px' }} />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                    :
                                                                    <></>
                                                            }
                                                        </Box>}
                                                    </span>
                                                </div>
                                            </span>
                                            <span className='invoice-details-column'>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Invoice Date</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.invoiceDate ? ConvertUTCToLocalDate(invoiceDetails?.invoiceDate) : ""}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Invoice Amount</span>
                                                    <span className='inv-detail-field-val'>
                                                        ${invoiceDetails?.invoiceAmount
                                                            .toLocaleString('en-US', {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            })
                                                        }
                                                    </span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Invoice Age</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.invoiceAge}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Division</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.division}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Dispute Code</span>
                                                    <span className='inv-detail-field-val'>
                                                        <Tooltip title={invoiceDetails?.disputeCode}>
                                                            {stringReducer(invoiceDetails?.disputeCode, 35)}
                                                        </Tooltip>
                                                    </span>
                                                </div>
                                            </span>
                                            <span className='invoice-details-column'>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Last Modified By</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.lastUpdatedBy}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Last Modified</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.lastUpdatedOn ? ConvertUTCToLocalDate(invoiceDetails?.lastUpdatedOn) : ""}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Last Submitted</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.lastSubmitted ? ConvertUTCToLocalDate(invoiceDetails?.lastSubmitted) : ""}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Last Disputed</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.lastDisputed ? ConvertUTCToLocalDate(invoiceDetails?.lastDisputed) : ""}</span>
                                                </div>
                                                <div className='invoice-details-row'>
                                                    <span className='inv-detail-field-name'>Completed Date</span>
                                                    <span className='inv-detail-field-val'>{invoiceDetails?.completedDate ? ConvertUTCToLocalDate(invoiceDetails?.completedDate) : ""}</span>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='invoice-attachments-container'>
                                        <div className='title-l2'> Attachments </div>
                                        <div className='invoice-attachments' style={{
                                            overflowY: 'auto',
                                            minHeight: 60,
                                            maxHeight: 60,
                                            width: '30%',
                                        }}>
                                            {invoiceDetails?.attachments.length > 0 ?
                                                (
                                                    <ul className='attach-list'>
                                                        {invoiceDetails?.attachments.map((item, index) => (
                                                            <li className='attach-item' key={index}><a className='attach-link' href={item.attachmentLocation} target='blank'>{item.attachmentName}</a></li>
                                                        ))}
                                                    </ul>
                                                )
                                                :
                                                (
                                                    <div style={{ marginTop: '20px', width: '100%', textAlign: 'center' }}>
                                                        No attachments found.
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <hr></hr>
                                    <div className='invoice-activity-container'>
                                        <div className='title-l2 activity'>
                                            <span className='activity-title'>Activity</span>
                                            <span className='activity-sort'>
                                                <Box sx={{
                                                    width: '20px',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    '&:hover .hover-icon': {
                                                        opacity: 1,
                                                    },
                                                    '&:hover .sort-desc-icon, &:hover .sort-asc-icon': {
                                                        opacity: 0
                                                    }
                                                }}>

                                                    <IconButton
                                                        size='small'
                                                        disableRipple={true}
                                                        className="hover-icon"
                                                        sx={{
                                                            opacity: 0,
                                                            transition: 'opacity 0.3s',
                                                            position: 'absolute',
                                                            marginLeft: 1,
                                                            cursor: 'pointer',
                                                            transform: 'translateY(50%)',
                                                            alignSelf: 'center'
                                                        }}
                                                        onClick={() => {
                                                            handleSortClicked()
                                                        }}
                                                    >
                                                        {
                                                            sortActivityAsc ?
                                                                <ArrowDownwardIcon sx={{ width: '70%', fontSize: '20px' }} />
                                                                :
                                                                <ArrowUpwardIcon sx={{ width: '70%', fontSize: '20px' }} />
                                                        }

                                                    </IconButton>

                                                    {
                                                        sortActivityAsc ? (
                                                            <IconButton
                                                                size='small'
                                                                disableRipple={true}
                                                                className="sort-asc-icon"
                                                                sx={{
                                                                    opacity: 1,
                                                                    transition: 'opacity 0.3s',
                                                                    marginLeft: 1,
                                                                    position: 'absolute',
                                                                    transform: 'translateY(50%)',
                                                                    alignSelf: 'center'
                                                                }}
                                                                onClick={() => {
                                                                    handleSortClicked()
                                                                }}
                                                            >
                                                                <ArrowUpwardIcon sx={{ width: '70%', fontSize: '20px' }} />
                                                            </IconButton>
                                                        )
                                                            :
                                                            <IconButton
                                                                size='small'
                                                                disableRipple={true}
                                                                className="sort-desc-icon"
                                                                sx={{
                                                                    opacity: 1,
                                                                    transition: 'opacity 0.3s',
                                                                    marginLeft: 1,
                                                                    position: 'absolute',
                                                                    transform: 'translateY(50%)',
                                                                    alignSelf: 'center'
                                                                }}
                                                                onClick={() => {
                                                                    handleSortClicked()
                                                                }}
                                                            >
                                                                <ArrowDownwardIcon sx={{ width: '70%', fontSize: '20px' }} />
                                                            </IconButton>
                                                    }
                                                </Box>
                                            </span>
                                        </div>
                                        <div className="tab-list">
                                            {
                                                signedInUser?.idTokenClaims?.roles?.includes("TreasuryUsers") ?
                                                    activityTabs.map((activity, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => { SetActiveActivityTab(index) }}
                                                            className={activeActivityTab === index ? 'tab active' : 'tab'}
                                                        >
                                                            {activity}
                                                        </div>

                                                    ))
                                                    :
                                                    <div className='tab active'>
                                                        Comments
                                                    </div>
                                            }
                                        </div>
                                        <hr className='actvity-tab-baseline'></hr>
                                        <div className='invoice-activities' style={{
                                            minHeight: 90,
                                            maxHeight: 90,
                                            overflowY: 'auto'
                                        }}>
                                            {!activityLoading ?
                                                (

                                                    activeActivityTab === 0 ?
                                                        (
                                                            <>
                                                                {invoiceActivity?.allActivities.map((activity, index) => (

                                                                    <div
                                                                        key={index}
                                                                        className='invoice-activity-item'
                                                                    >
                                                                        <AccountCircleIcon className='profile-icon' />
                                                                        <span className='activity-user'>{activity.createdBy}</span>
                                                                        <span className='activity-comment'>
                                                                            <Tooltip title={activity.comments}>
                                                                                {stringReducer(activity.comments, 50)}
                                                                            </Tooltip>
                                                                        </span>
                                                                        <span className='comment-timestamp'>
                                                                            {ConvertUTCToLocalTime(activity.createdDateTime)}
                                                                        </span>
                                                                    </div>
                                                                ))}
                                                            </>
                                                        )
                                                        :
                                                        activeActivityTab === 1 ?
                                                            (
                                                                <>
                                                                    {invoiceActivity?.comments.map((activity, index) => (

                                                                        <div
                                                                            key={index}
                                                                            className='invoice-activity-item'
                                                                        >
                                                                            <AccountCircleIcon className='profile-icon' />
                                                                            <span className='activity-user'>{activity.createdBy}</span>
                                                                            <span className='activity-comment'>
                                                                                <Tooltip title={activity.comments}>
                                                                                    {stringReducer(activity.comments, 50)}
                                                                                </Tooltip>
                                                                            </span>
                                                                            <span className='comment-timestamp'>
                                                                                {ConvertUTCToLocalTime(activity.createdDateTime)}
                                                                            </span>
                                                                        </div>
                                                                    ))}
                                                                </>
                                                            )
                                                            :
                                                            activeActivityTab === 2 ?
                                                                (
                                                                    <>
                                                                        {invoiceActivity?.statusChanges.map((activity, index) => (

                                                                            <div
                                                                                key={index}
                                                                                className='invoice-activity-item'
                                                                            >
                                                                                <AccountCircleIcon className='profile-icon' />
                                                                                <span className='activity-user'>{activity.createdBy}</span>
                                                                                <span className='activity-comment'>
                                                                                    <Tooltip title={activity.comments}>
                                                                                        {stringReducer(activity.comments, 50)}
                                                                                    </Tooltip>
                                                                                </span>
                                                                                <span className='comment-timestamp'>
                                                                                    {ConvertUTCToLocalTime(activity.createdDateTime)}
                                                                                </span>
                                                                            </div>
                                                                        ))}
                                                                    </>
                                                                )
                                                                :
                                                                null
                                                )
                                                :
                                                <LoaderComponent
                                                    ht={'100%'}
                                                />
                                            }
                                        </div>
                                        {activeActivityTab === 0 || activeActivityTab === 1 ?
                                            (
                                                <div className='add-comment-container'>
                                                    <span className='comment-in-container'>
                                                        <OutlinedInput
                                                            className='comment-input'
                                                            placeholder='Comments'
                                                            value={commentText}
                                                            onChange={handleComment}
                                                        />
                                                    </span>
                                                    <span className='add-button-container'>
                                                        <Button className="add-comment-button" variant="contained" color="primary" onClick={() => {
                                                            addCommentClicked()
                                                        }}><ModeCommentOutlinedIcon className='comment-icon' /> <span className='add-button-text'>Add Comment</span> </Button>
                                                    </span>

                                                </div>
                                            )
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>)
                        :
                        errorCode === 404 ?
                            <PageNotFound />
                            :
                            errorCode === 422 ?
                                <UnauthorizedComponent
                                    message={"This Invoice is no longer disputed. Redirecting to Invoices list."}
                                />
                                :
                                null
                )
                :
                (
                    <LoaderComponent
                        ht={440}
                    />
                )
            }
            {
                Action &&
                <CustomizedSnackbars
                    message={message}
                    snackstatus={Action}
                    snacktype={snacktype}
                    onCloseCallback={handleSnapClose}
                />
            }
            {
                isDialog &&
                <ResponsiveDialog
                    value={dialogvalue}
                    Title={"Add/Edit Labels"}
                    isConfirmDialog={confirmdialog}
                    onsavecallback={handlefinalLabelChange}
                    oncancelcallback={handlefinalLabelChange}
                    passSelectedInvoice={invoiceDetails}
                    isViewOnly={isView}
                />
            }
            {
                isStatusChange && (
                    <StatusDialog
                        displayInfo={dialogvalue}
                        onsavecallback={handlefinalStatusChange}
                        oncancelcallback={handlefinalStatusChange}
                        statusInfo={statusPayload}
                        passSelectedInvoice={invoiceDetails}
                        disputeInfo={disputeInfo}
                    />
                )
            }
        </div >
    )
}

export default InvoiceDetails
