/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import { Box, InputBase, Grid } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import { InvoiceContext } from '../../context/invoiceContext';


function SearchBar({
    searchApplyCallBack,
    placeholder,
    isAutoFocus,
    isSerachIcon,
    isCrossIcon,
    textColor,
    isInvoiceList
}) {
    const [Value, setValue] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState(Value);
    const { appliedSearch, InitializeAppliedSearch } = useContext(InvoiceContext);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedQuery(Value);
        }, 300); // 300ms debounce delay

        return () => {
            clearTimeout(handler);
        };
    }, [Value]);

    useEffect(() => {
        if (searchApplyCallBack) {
            if (debouncedQuery.length >= 3 || debouncedQuery === "") {
                searchApplyCallBack(debouncedQuery);
                if (debouncedQuery !== "") {
                    if (isInvoiceList)
                        InitializeAppliedSearch(debouncedQuery);
                }
            }
        }
    }, [debouncedQuery]);

    useEffect(() => {
        if (appliedSearch) {
            if (isInvoiceList) {
                setValue(appliedSearch);
            }
        }
    }, []);

    const clearSearch = () => {
        setValue("");
        if (searchApplyCallBack) {
            searchApplyCallBack("");
            if (isInvoiceList)
                InitializeAppliedSearch();
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            setValue(event.target.value);
            if (Value !== "") {
                if (searchApplyCallBack) {
                    searchApplyCallBack(Value);
                    if (isInvoiceList)
                        InitializeAppliedSearch(Value);
                }
            }
        }
    };

    const search = () => {
        if (Value !== "") {
            if (searchApplyCallBack) {
                searchApplyCallBack(Value);
                if (isInvoiceList)
                    InitializeAppliedSearch(Value);
            }
        }
    }

    return (
        <InputBase
            placeholder={placeholder}
            autoComplete='off'
            autoFocus={isAutoFocus}
            onKeyDown={handleKeyDown}
            sx={{ width: "100%", height: "100%", fontSize: 12, color: textColor }}
            value={Value}
            onChange={(e) => setValue(e.target.value)}
            startAdornment={
                <Box
                    onClick={() => search()}
                    sx={{ cursor: "pointer", margin: "5px 5px 0px 10px" }}
                >
                    {isSerachIcon && <SearchIcon />}
                </Box>
            }
            endAdornment={
                <Grid sx={{ paddingRight: "5px" }}>
                    {Value && (
                        <Box
                            onClick={clearSearch}
                            sx={{
                                cursor: "pointer",
                                margin: "5px 5px 0 0",
                            }}
                        >
                            {isCrossIcon && <CloseIcon />}
                        </Box>
                    )}
                </Grid>
            }
        />
    )
}

export default SearchBar;
