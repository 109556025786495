/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Typography, Box, TextField, Select, MenuItem, OutlinedInput, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { stringReducer } from '../../utils';

const ITEM_HEIGHT = 20;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 100,
        },
    },
};

export default function StatusDialog({
    displayInfo,
    onsavecallback,
    oncancelcallback,
    passSelectedInvoice,
    statusInfo,
    disputeInfo
}) {
    const [open, setOpen] = useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [chipData] = useState([]);
    const [comments, setComments] = useState();
    const [disputeName, setDisputeName] = useState([]);
    const [selectedDispute, setSelectedDispute] = useState([]);
    const [passDispute, setPassDispute] = useState([]);
    const [err, setErr] = useState(false);


    useEffect(() => {
        populateDisputeItems()
    }, [disputeInfo])

    const populateDisputeItems = () => {
        if (disputeInfo) {
            const tempDisputes = disputeInfo.map((data) => {
                const disputeObj = {
                    Id: data.disputeCodeId,
                    Name: data.disputeCode
                }
                return disputeObj;
            })
            setDisputeName(tempDisputes);
        }
    }

    const handleClose = () => {
        setOpen(false);
        if (oncancelcallback) {
            oncancelcallback(false);
        }
    };
    const handleconfirm = () => {
        if (statusInfo.Name === "Internal Dispute") {
            if (selectedDispute.length > 0) {
                if (onsavecallback) {
                    handleClose();
                    onsavecallback(true, comments, chipData, passDispute);
                }
            }
            else {
                setErr(true)
            }
        }
        else {
            if (onsavecallback) {
                handleClose();
                onsavecallback(true, comments, chipData, selectedDispute);
            }
        }

    }
    const handlecancel = () => {
        if (oncancelcallback) {
            handleClose();
            oncancelcallback(false);
        }
    }

    const handleCommentschange = (event) => {
        setComments(event.target.value)
    }

    const handleDisputeChange = (event) => {
        setErr(false)
        const { target: { value } } = event;
        setPassDispute(event.target.value);
        setSelectedDispute(typeof value.Name === 'string' ? value.Name.split(',') : value);
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    borderRadius: '20px',
                },
            }}
        >
            <Box p={3.5}>
                <DialogTitle mb={2} sx={{ padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h2" sx={{ fontWeight: 'bold' }}>
                        Status Change
                    </Typography>
                    <IconButton edge="end" onClick={handleClose}
                        disableRipple={true}
                        sx={{
                            fontSize: '15px'
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Typography
                    variant="h4"
                    sx={{
                        marginTop: '15px',
                        fontSize: '14px'
                    }}
                >
                    {displayInfo}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1, height: '14px' }}>

                </Box>
                {
                    (statusInfo.Name === "Internal Dispute") && (
                        <Select
                            displayEmpty
                            value={selectedDispute}
                            onChange={handleDisputeChange}
                            style={{ width: '100%', height: '24px' }}
                            input={<OutlinedInput
                                sx={{
                                    borderRadius: '10px',
                                    marginTop: '20px',
                                    fontSize: '12px',
                                    height: '100%'
                                }}
                            />}
                            renderValue={(selected) => {
                                if (selected.length === 0) {
                                    return <Box>Dispute Code</Box>;
                                }
                                return selected.join(', ');
                            }}
                            MenuProps={MenuProps}
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{
                                width: '70%',
                                fontSize: 12
                            }}
                        >
                            {disputeName.map((dispute) => (
                                <MenuItem
                                    key={dispute.Id}
                                    value={{ Id: dispute.Id, Name: dispute.Name }}
                                    sx={{ fontSize: 10 }}
                                >
                                    {stringReducer(dispute.Name, 80)}
                                </MenuItem>
                            ))}
                        </Select>
                    )
                }
                {
                    (statusInfo.Name === "Internal Dispute") &&
                    (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1, height: '14px' }}>
                        {err && (

                            <Typography variant='h4' color='red' sx={{ fontSize: '12px' }}>
                                Dispute Code should not be empty.
                            </Typography>
                        )}
                    </Box>)
                }
                <TextField
                    value={comments}
                    onChange={handleCommentschange}
                    placeholder='Add Comments'
                    InputProps={{ disableUnderline: true }}
                    style={{
                        height: '24px',
                        marginTop: '10px',
                        display: 'grid'
                    }}
                    sx={{
                        width: '100%',
                        height: '100%',
                        fontSize: '12px',
                        padding: '1px',
                        color: 'grey',
                        border: '1px solid grey',
                        borderRadius: '10px',
                        '& .MuiInputBase-input': {
                            fontSize: '12px',
                            height: '100%',
                            padding: '1px',
                            paddingLeft: '10px'
                        },
                        "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                                border: "none",
                                height: '100%'
                            },
                            "&:hover fieldset": {
                                border: "none",
                                height: '100%'
                            },
                        }
                    }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 1, height: '14px' }}>

                </Box>
                <DialogActions sx={{ padding: 0, marginTop: '25px' }}>
                    <Button
                        autoFocus
                        onClick={handleconfirm}
                        sx={{
                            textTransform: 'capitalize',
                            backgroundColor: (theme) => theme.palette.primary.main,
                            width: '120px',
                            height: '30px',
                            borderRadius: '20px',
                            color: '#FFF',
                            ':hover': {
                                backgroundColor: (theme) => theme.palette.primary.whitish,
                            },
                        }}
                    >
                        Confirm
                    </Button>
                    <Button
                        onClick={handlecancel}
                        sx={{
                            textTransform: 'capitalize',
                            width: '120px',
                            height: '30px',
                            borderRadius: '20px',
                            fontSize: '15px',
                            marginLeft: '5px',
                            color: (theme) => theme.palette.primary.main,
                            ':hover': {
                                backgroundColor: (theme) => theme.palette.primary.whitish,
                                color: 'white',
                            },
                        }}
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
}
