/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card } from '@mui/material';

import { stringReducer } from '../../utils';

class BarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            series: [{
                name: stringReducer(props?.graphTitle, 10),
                data: props?.Data
            }],
            options: {
                chart: {
                    type: 'bar',
                    height: 220,
                    toolbar: {
                        show: false
                    }
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '50%',
                        endingShape: 'rounded',
                        dataLabels: {
                            position: 'center', // top, center, bottom
                        },
                    },
                },
                dataLabels: {
                    enabled: true,
                    style: {
                        fontSize: '12px',
                        colors: ["#B5C0D0"]
                    }
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: props.XLabels,
                    title: {
                        text: props?.graphTitle === 'Dispute Volume by Code' ? 'Dispute Code' : 'Aging Bucket',
                        style: {
                            fontSize: '12px',
                            fontWeight: 'regular',
                            color: 'black'
                        },
                        floating: true
                    },
                    labels: {
                        rotate: 0,
                        trim: true,
                        hideOverlappingLabels: false,
                        formatter: function (value) {
                            return value;
                        },
                        title: {
                            formatter: function (value) {
                                return value;
                            }
                        }
                    },
                },
                yaxis: {
                    title: {
                        text: props.YLabel,
                        style: {
                            fontSize: '12px',
                            fontWeight: 'regular',
                            color: 'black'
                        }
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    enabled: false
                }
            },
        };
    }

    render() {
        return (
            <Card
                sx={{
                    height: '100%'
                }}
            >
                <ReactApexChart
                    options={this.state.options}
                    series={this.state.series}
                    type="bar"
                    height="100%"
                />
            </Card>

        );
    }
}

export default BarChart;