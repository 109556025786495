/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Virtuoso } from 'react-virtuoso';
import TableItem from '../../components/tableitem';
import { Box, Typography, TextField, Button, Grid, Card } from '@mui/material';
import CustomizedSnackbars from '../../components/snackbar';
import ResponsiveDialog from '../../components/dialog';
import LoaderComponent from '../../components/loader';
import SearchBar from '../../components/searchbar';

// api Import 
import { getDisputeCodesList, editDisputeCode, createNewDisputeCode, deleteDisputeCode } from '../../services/api';

function SettingsPage() {
    const [Disputes, SetDisputes] = useState([]);
    const [IsDisputeChange, SetDisputeChange] = useState(false);
    const [newDispute, SetnewDispute] = useState("");
    const [isvalid, setisvalid] = useState(true);
    const [Action, SetAction] = useState(false);
    const [Message, SetMessage] = useState("");
    const [isDialog, SetIsDialog] = useState(false);
    const [errmsg, Seterrmsg] = useState("");
    const [useraction, setuseraction] = useState("create");
    const [dialogvalue, setdialogvalue] = useState("");
    const [valuetodelete, setvaluetodelete] = useState("");
    const [snacktype, setSnackType] = useState("");
    const [dialogTitle, setDialogTitle] = useState("");
    const [fetchPayload, setFetchPayload] = useState({
        searchKeyword: null
    })

    const [loading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        getDisputeCodesList(fetchPayload)
            .then((res) => {
                SetDisputes(res)
            })
            .catch((_err) => { 
                setSnackType("error");
                SetAction(true);
                SetMessage("Error retrieving dispute codes. Refresh the page or contact System Administrator if the issue persists.");
             })
            .finally(() => setIsLoading(false));
    }, [IsDisputeChange, fetchPayload])

    const handledisputeedit = (value) => {
        // update api
        Disputes.forEach((d) => {
            if (d.disputeCodeId === value.Id) {
                const payload = {
                    disputeCodeId: d.disputeCodeId,
                    disputeCode: value.Reason
                }
                editDisputeCode(payload)
                    .then((res) => {
                        if (res.isSuccessful) {
                            SetAction(true)
                            SetDisputeChange(prevState => !prevState);
                            SetMessage("Dispute Code edited.");
                            setSnackType("success");
                        }
                        else {
                            SetAction(true);
                            SetDisputeChange(prevState => !prevState);
                            SetMessage(res.message);
                            setSnackType("error");
                        }
                    })
                    .catch((e) => {
                        SetAction(true);
                        SetDisputeChange(prevState => !prevState);
                        SetMessage(e.message);
                        setSnackType("error");
                    })
            }
        })
    }

    const handledisputedelete = (value) => {
        SetIsDialog(true);
        setDialogTitle("Delete Dispute Code");
        setuseraction("Delete");
        setdialogvalue(`Do you want to delete the Dispute code - ${value.Reason}?`);
        setvaluetodelete(value);
    }

    const handlenewDisputechange = (event) => {
        if (event.target.value.length < 100) {
            setisvalid(true);
            SetnewDispute(event.target.value);
        }
        else {
            setisvalid(false);
            Seterrmsg("Dispute Code should be less than 100 characters.");
        }
    };

    const handledisputesave = () => {
        setuseraction("create")
        if (newDispute === "") {
            setisvalid(false);
            Seterrmsg("This field cannot be empty.");
        }
        else {
            SetIsDialog(true);
            setDialogTitle("Create new Dispute Code");
            setdialogvalue(` Do you want to confirm creation of "${newDispute}" dispute code ?`);
        }
    };
    const handleSnapClose = (value) => {
        SetAction(value);
    };

    const handlefinalsave = (value) => {
        if (value) {

            if (useraction === 'create') {
                SetIsDialog(false)
                setisvalid(true)
                const newDisputeObject = {
                    disputeCode: newDispute
                };
                createNewDisputeCode(newDisputeObject)
                    .then((res) => {
                        if (res.isSuccessful) {
                            SetDisputeChange(prevState => !prevState);
                            SetnewDispute("");
                            SetAction(true);
                            setSnackType("success");
                            SetMessage("Dispute Code created.")
                        }
                        else {
                            SetnewDispute("");
                            SetAction(true);
                            SetMessage(res.message);
                            setSnackType("error");
                        }
                    })
                    .catch((e) => {
                        SetAction(true);
                        SetMessage(e.message);
                        setSnackType("error");
                    })
            }
            else {
                Disputes.forEach((d) => {
                    if (d.disputeCodeId === valuetodelete?.Id) {
                        const payload = {
                            disputeCodeId: d.disputeCodeId
                        }
                        deleteDisputeCode(payload)
                            .then((res) => {
                                SetDisputeChange(prevState => !prevState)
                                if (res.isSuccessful) {
                                    SetAction(true);
                                    SetMessage("Dispute Code deleted.");
                                    setSnackType("success");
                                }
                                else {
                                    SetAction(true);
                                    setSnackType("error");
                                    SetMessage(res.message);
                                }
                            })
                            .catch((_e) => {
                                SetAction(true);
                                SetMessage('An error occured during the request!');
                                setSnackType("error");
                            })
                    }
                })
            }

        }
        else {
            SetIsDialog(false)
            setisvalid(true)
        }
    };

    const handleSearchApply = (searchword) => {
        setFetchPayload({
            searchKeyword: searchword
        });
    }

    return (
        <Grid
            Container
            sx={{
                display: 'flex',
                height: '100%',
                width: '100%'
            }}>
            {/* Right Container */}
            <Grid
                item
                sm={7}
                lg={6}
                xs={12}
                md={6}
                xl={7}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: '100%'
                }}
            >
                <Grid
                    item
                    sx={{
                        height: '100%',
                        width: '80%',
                    }}>
                    <Grid
                        item
                        sm={12}
                        lg={12}
                        xs={12}
                        md={12}
                        xl={12}
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            height: '10%',
                            paddingLeft: '5px'
                        }}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 'bold',
                            }}
                        >
                            Dispute Codes
                        </Typography>
                        <Card
                            sx={{
                                borderRadius: "20px",
                                width: "60%",
                                height: 25,
                                backgroundColor: (theme) => theme.palette.primary.main,
                                "&::placeholder": {
                                    textAlign: "center",
                                },
                            }}
                        >
                            <SearchBar
                                placeholder="Search for Dispute Codes"
                                searchApplyCallBack={handleSearchApply}
                                isSerachIcon={true}
                                isCrossIcon={true}
                                isAutoFocus={false}
                                isInvoiceList={false}
                                textColor="white"
                            />
                        </Card>
                    </Grid>
                    {
                        !loading ? (
                            Disputes.length > 0 ? (
                                <Grid
                                    item
                                    sx={{
                                        height: '90%',
                                        width: '100%',
                                    }}>
                                    <Virtuoso
                                        data={Disputes}
                                        itemContent={(index, item) => (
                                            <TableItem
                                                key={index}
                                                disputereason={item.disputeCode}
                                                disputeCodeId={item.disputeCodeId}
                                                oneditcallback={handledisputeedit}
                                                ondeletecallback={handledisputedelete}
                                            />
                                        )}
                                    />
                                </Grid>
                            ) : (
                                <Grid
                                    item
                                    sx={{
                                        height: '90%',
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center'
                                    }}>
                                    <Typography variant='h5'>
                                        No records found
                                    </Typography>
                                </Grid>
                            )
                        ) : (
                            <Grid
                                item
                                sx={{
                                    height: '90%',
                                    width: '100%',
                                }}>
                                <LoaderComponent
                                />
                            </Grid>
                        )
                    }
                </Grid>
            </Grid>
            {/* Left Container */}
            <Grid
                item
                sm={7}
                lg={6}
                xs={12}
                md={6}
                xl={7}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}
            >
                <Grid
                    p={4}
                    sx={{
                        width: '85%',
                        border: '2px solid',
                        borderRadius: '8px',
                    }}
                >
                    <Typography
                        variant="h4"
                        mb={3}
                        sx={{
                            fontWeight: 'bold',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        Create new dispute code
                    </Typography>

                    <TextField
                        fullWidth
                        value={newDispute}
                        onChange={handlenewDisputechange}
                        InputProps={{ disableUnderline: true }}
                        placeholder="Enter dispute code here"
                        size="small"
                        sx={{
                            fontSize: '14px',
                            border: `2px solid ${isvalid ? 'black' : '#f44336'}`,
                            marginTop: '10px',
                            borderRadius: '10px',
                            '& .MuiOutlinedInput-root': {
                                fontSize: '14px',
                                '& fieldset': {
                                    border: 'none'
                                },
                                '&:hover fieldset': {
                                    border: 'none'
                                }
                            }
                        }}
                    />
                    <Box>
                        {isvalid ? (
                            <Box sx={{ height: '27px' }} />
                        ) : (
                            <Box
                                sx={{
                                    height: '27px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: (theme) => theme.palette.error.main,
                                        fontSize: '12px',
                                        marginLeft: '5px',
                                    }}
                                >
                                    {errmsg}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ display: 'flex' }}>
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                backgroundColor: (theme) => theme.palette.primary.main,
                                width: '120px',
                                fontSize: '14px',
                                height: '30px',
                                borderRadius: '20px',
                                color: '#FFF',
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                },
                            }}
                            onClick={handledisputesave}
                        >
                            Save
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                width: '120px',
                                height: '30px',
                                borderRadius: '20px',
                                fontSize: '14px',
                                marginLeft: '15px',
                                color: (theme) => theme.palette.primary.main,
                                ':hover': {
                                    backgroundColor: (theme) => theme.palette.primary.whitish,
                                    color: 'white'
                                }
                            }}
                            onClick={() => {
                                SetnewDispute('');
                                setisvalid(true);
                            }}
                        >
                            Clear
                        </Button>
                    </Box>
                </Grid>
            </Grid>
            {
                Action &&
                <CustomizedSnackbars
                    snacktype={snacktype}
                    message={Message}
                    snackstatus={Action}
                    onCloseCallback={handleSnapClose}
                />
            }
            {
                isDialog &&
                <ResponsiveDialog
                    value={dialogvalue}
                    Title={dialogTitle}
                    isConfirmDialog={true}
                    onsavecallback={handlefinalsave}
                    oncancelcallback={handlefinalsave}
                />
            }
        </Grid >
    );
}

export default SettingsPage;



