import axios from 'axios';
import { PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalConfig, loginRequest } from '../authConfig';

const msalInstance = new PublicClientApplication(msalConfig);

const API_URL = process.env.REACT_APP_ENDPOINT_URL;

const Client = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-type': 'application/json'
    }
});

let isRefreshing = false;
let refreshQueue = [];

const isTokenExpired = (expiryTime) => {
    const currentTime = new Date();
    return new Date(expiryTime) < currentTime;
};

Client.interceptors.request.use(
    async config => {
        const token = localStorage.getItem('accessToken');
        const tokenExpiry = localStorage.getItem('tokenExpiry');

        const refreshToken = async () => {
            try {
                const silentRequest = {
                    ...loginRequest,
                    account: msalInstance.getActiveAccount()
                };
                await msalInstance.initialize();
                const response = await msalInstance.acquireTokenSilent(silentRequest);
                const newToken = response.accessToken;
                const newExpiry = response.expiresOn;
                localStorage.setItem('accessToken', newToken);
                localStorage.setItem('tokenExpiry', newExpiry);
                return newToken;
            } catch (error) {
                if (error instanceof InteractionRequiredAuthError) {
                    msalInstance.acquireTokenRedirect(loginRequest).then(
                        function
                            (accessTokenResponse) {
                            // Acquire token interactive success
                            const accessToken = accessTokenResponse.accessToken;
                            localStorage.setItem('accessToken', accessToken);
                            const newExpiry = accessTokenResponse.extExpiresOn;
                            localStorage.setItem('tokenExpiry', newExpiry);
                            return accessToken;
                        }).catch(
                            function
                                (error) {
                                throw error;
                            });
                }
                else{
                    throw error;
                }
                return null;
            }
        };

        if (!token || isTokenExpired(tokenExpiry)) {
            if (!isRefreshing) {
                isRefreshing = true;
                refreshToken().then(newToken => {
                    config.headers.Authorization = `Bearer ${newToken}`;
                    config.baseURL = API_URL; // Ensure baseURL is reset after token refresh
                    refreshQueue.forEach(cb => cb(newToken));
                    refreshQueue = [];
                    isRefreshing = false;
                }).catch(error => {
                    refreshQueue = [];
                    isRefreshing = false;
                    console.error('Token refresh failed:', error);
                });
            }

            return new Promise((resolve, reject) => {
                refreshQueue.push(newToken => {
                    config.headers.Authorization = `Bearer ${newToken}`;
                    resolve(config);
                });
            });
        }

        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

Client.interceptors.response.use(
    // unwrap response data
    ({ data }) => data,
    // catch statusCode != 200 responses and format error
    error => {
        if (error?.response) {
            const errorData = {
                ...error.response.data,
                status: error.response.status
            };
            return Promise.reject(errorData);
        }
        return Promise.reject(error);
    }
);

export default Client;
