/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useContext } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import routes from "./constants/routes.json";
import LoginPage from "./containers/login";
import AppLayout from "../src/containers/layout";
import PageNotFound from "../src/containers/pagenotfound";
import { InvoiceContext } from "./context/invoiceContext";
import RouteGuard from "./components/guard";
import { appRoles } from "./authConfig";

// Screens
import HomePage from "../src/containers/home";
import InvoicePage from "../src/containers/invoice";
import SettingsPage from "../src/containers/settings";
import InvoiceDetails from "../src/containers/invoicedetail";

function AppRouter({ instance }) {
    const location = useLocation();
    const pathname = location?.pathname;
    const navigate = useNavigate();
    const { InitializeSignedInUser } = useContext(InvoiceContext);

    useEffect(() => {
        const checkAuth = async () => {
            if (pathname !== '/login') {
                try {
                    const activeAccount = instance?.getActiveAccount();
                    if (activeAccount) {
                        InitializeSignedInUser(activeAccount);
                        localStorage.setItem('Username', activeAccount?.name);
                    } else {
                        navigate(routes?.LOGIN);
                    }
                } catch (error) {
                    console.error('Authentication Error:', error);
                    navigate(routes?.LOGIN);
                }
            }
        };
        checkAuth();
    }, [instance, pathname]);

    return (
        <Routes>
            <Route
                exact path={routes?.LOGIN}
                element={<LoginPage />}
            />
            <Route
                exact path={routes?.HOME}
                element={
                    <RouteGuard roles={[appRoles.TreasuryUsers]}>
                        <AppLayout screen={<HomePage />} screenTitle={"e-Billing Dashboard"} />
                    </RouteGuard>
                }
            />
            <Route
                exact path={routes?.INVOICE}
                element={
                    <RouteGuard roles={[appRoles.TreasuryUsers, appRoles.RegionalUsers]}>
                        <AppLayout screen={<InvoicePage />} screenTitle={"Invoices"} />
                    </RouteGuard>
                }
            />
            <Route
                exact path={routes?.INVOICEDETAILS}
                element={
                    <RouteGuard roles={[appRoles.TreasuryUsers, appRoles.RegionalUsers]}>
                        <AppLayout screen={<InvoiceDetails />} screenTitle={"Invoice Detail"} />
                    </RouteGuard>
                }
            />
            <Route
                exact path={routes?.SETTINGS}
                element={
                    <RouteGuard roles={[appRoles.TreasuryUsers]}>
                        <AppLayout screen={<SettingsPage />} screenTitle={"Settings"} />
                    </RouteGuard>
                }
            />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    );
}

export default AppRouter;