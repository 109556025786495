/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import { Card, Box, Typography, Slider, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
const MAX = 10;
const MIN = 0;
const marks = [
    {
        value: MIN,
        label: '',
    },
    {
        value: MAX,
        label: '',
    },
];

export default function LineBar({
    min,
    max,
    avg
}) {
    const [val, setVal] = useState(avg || 0);

    useEffect(() => {
        if (avg !== undefined) {
            setVal(avg);
        }
    }, [avg]);

    return (
        <Card sx={{
            height: '100%',
        }}>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '20px'
            }}>
                <Box sx={{
                    width: '80%'
                }}>
                    <Slider
                        marks={marks}
                        step={10}
                        valueLabelDisplay="auto"
                        value={val}
                        min={MIN}
                        max={MAX}
                        sx={{
                            cursor: 'default',
                            marginTop: '10%',
                            '& .MuiSlider-track': {
                                background: 'transparent',
                                border: 'none',
                                cursor: 'default'
                            },
                            '& .MuiSlider-thumb': {
                                color: '#C8C8C8',
                                border: '2px solid grey',
                                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                    boxShadow: 'none',
                                    cursor: 'default'
                                },
                            },
                            '& .MuiSlider-rail': {
                                opacity: 1,
                                height: '50%',
                                background: `linear-gradient(90deg, 
                                #4caf50 0%, #4caf50 33.33%, 
                                #ffb74d 33.33%, #ffb74d 66.66%, 
                                #ef5350 66.66%, #ef5350 100%)`,
                                border: 'none',
                                cursor: 'default'
                            },
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="body2"
                            sx={{ cursor: 'pointer' }}
                        >
                            {MIN}
                        </Typography>
                        <Typography
                            variant="body2"
                            sx={{ cursor: 'pointer' }}
                        >
                            {MAX}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '10px'
            }}>
                <Paper sx={{
                    width: '20%',
                    height: '20%',
                    boxShadow: "1px 1px 2px black",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: '10%'
                }}>
                    <Typography>
                        Min {min}
                    </Typography>
                </Paper>
                <Paper sx={{
                    width: '20%',
                    height: '20%',
                    boxShadow: "1px 1px 2px black",
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    marginRight: '10%'
                }}>
                    <Typography>
                        Max {max}
                    </Typography>
                </Paper>
            </Box>
        </Card>
    );
}