import React from 'react';
import { Grid, Typography } from '@mui/material';

function NoResults() {
    return (
        <Grid
            container
            style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '92%',
                width: "100%"
            }}>
            <Typography variant='h4'>
                No Results Found
            </Typography>
        </Grid>
    )
}

export default NoResults
